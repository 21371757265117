import Header from "./Header"
import Sidebar from "./leftSidebar"
import Footer from "./Footer";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './css/dashboard.css';
import './css/App.css';









export default function Plans(){
      const [orderId, setOrderId] = useState('');

  
  
  // Razorpay integration Gold
 
  const handlePaymentGold = async () => {
        try {
            const { data } = await axios.post('https://api.evriddhi.com/createOrder');
            setOrderId(data.id);

            const options = {
                key: 'rzp_test_ZsR3QeFIMk5oRF',
                amount: '699900', // Amount in paise
                currency: 'INR',
                name: 'Your Company Name',
                description: 'Test Transaction',
                image: 'https://evriddhi.com/logo.png',
                order_id: data.id, // This is the order_id created on the backend
                handler: async function (response) {
                    try {
                        const result = await axios.post('https://api.evriddhi.com/verifyPayment', {
                            order_id: response.razorpay_order_id,
                            payment_id: response.razorpay_payment_id,
                            signature: response.razorpay_signature
                        });
                        if (result.data.success) {
                            alert('Payment successful!');
                        } else {
                            alert('Payment verification failed');
                        }
                    } catch (error) {
                        console.error(error);
                    }
                },
                prefill: {
                    name: 'John Doe',
                    email: 'john.doe@example.com',
                    contact: '9999999999'
                },
                notes: {
                    address: 'Hello World'
                },
                theme: {
                    color: '#b31515'
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error(error);
        }
    };
  
  
  
  // Razorpay integration platinum
 
  const handlePaymentPlatinum = async () => {
        try {
            const { data } = await axios.post('https://api.evriddhi.com/createOrder');
            setOrderId(data.id);

            const options = {
                key: 'rzp_test_ZsR3QeFIMk5oRF',
                amount: '999900', // Amount in paise
                currency: 'INR',
                name: 'Your Company Name',
                description: 'Test Transaction',
                image: 'https://evriddhi.com/logo.png',
                order_id: data.id, // This is the order_id created on the backend
                handler: async function (response) {
                    try {
                        const result = await axios.post('https://api.evriddhi.com/verifyPayment', {
                            order_id: response.razorpay_order_id,
                            payment_id: response.razorpay_payment_id,
                            signature: response.razorpay_signature
                        });
                        if (result.data.success) {
                            alert('Payment successful!');
                        } else {
                            alert('Payment verification failed');
                        }
                    } catch (error) {
                        console.error(error);
                    }
                },
                prefill: {
                    name: 'John Doe',
                    email: 'john.doe@example.com',
                    contact: '9999999999'
                },
                notes: {
                    address: 'Hello World'
                },
                theme: {
                    color: '#b31515'
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error(error);
        }
    };
  
  
  
  
  // Razorpay silver
  
  
  
   const handlePaymentSilver = async () => {
        try {
            const { data } = await axios.post('https://api.evriddhi.com/createOrder');
            setOrderId(data.id);

            const options = {
                key: 'rzp_test_ZsR3QeFIMk5oRF',
                amount: '499900', // Amount in paise
                currency: 'INR',
                name: 'Your Company Name',
                description: 'Test Transaction',
                image: 'https://evriddhi.com/logo.png',
                order_id: data.id, // This is the order_id created on the backend
                handler: async function (response) {
                    try {
                        const result = await axios.post('https://api.evriddhi.com/verifyPayment', {
                            order_id: response.razorpay_order_id,
                            payment_id: response.razorpay_payment_id,
                            signature: response.razorpay_signature
                        });
                        if (result.data.success) {
                            alert('Payment successful!');
                        } else {
                            alert('Payment verification failed');
                        }
                    } catch (error) {
                        console.error(error);
                    }
                },
                prefill: {
                    name: 'John Doe',
                    email: 'john.doe@example.com',
                    contact: '9999999999'
                },
                notes: {
                    address: 'Hello World'
                },
                theme: {
                    color: '#b31515'
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error(error);
        }
    };
  
  
  
  
  
  



    // variables user is the current session user and navigate is the page they need to navigate to

    const backend= "https://api.evriddhi.com"
    const [user, setName]= useState('')
    const navigate= useNavigate();
    axios.defaults.withCredentials=true;



    // calling api to display details on dashboard 


    useEffect(()=>{
        axios.get(backend+'/dashboard')
        .then(res=> {
            if(res.data.valid){
              setName(res.data.sessionData)
              console.log(res.data.sessionData)
        }else{
            console.log(res.data)
        }
    })
    .catch(err=> console.log(err));
    },[]);





    // jsx code for the display page

   return( 
   <div className="container-fluid ">
    <div className="row bg-gray vh-100">
        <div className="col-sm-2 bg-white box-shadow border-round-lite static-section ">
            <Sidebar/>
        </div>
        <div className="col-sm-10 scrollable-section">
            <div className="content">
            <Header />
            <div className="row center-all margin-2">
                
                <div className="col-sm-12 border-round-lite">
         
                    <div className="row  bg-white center align-items-start border-round-lite box-shadow col-gap-20">
                    <div className="row padding-sm margin-top-pos-10px">
                          <h2 className="text-center plans-pricing"> Plans & Pricings</h2>
                          <p className="text-center"><span>Enjoy Your Free Trial and select the best plan according to your business needs</span></p>
                    </div>
                    <div className="col-sm-3 padding-md  border-round-lite box-shadow  plans">
                        <h3 className="text-center">Platinum</h3>
                        <h3 className="text-center"> <span> ₹ 9,999 /- </span></h3><br></br>
                        <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Vriddhi Site:1 </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">E Business Card </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20 col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Display Offers </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Show Products/ Service </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Enquiry/ Leads </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Appointments </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Higher Google Ranking </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">E-Greeting Cards </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>


                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">AI Generated Posts </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>


                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Support </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                            <div className="row margin-top-pos-10px">
                                <div className="col center-all">
                                  <button className="details-btn border-round-lite box-shadow" onClick={handlePaymentPlatinum}>Select Plan</button>
                                </div>
                            </div>

                    </div>





                    <div className="col-sm-3 padding-md border-round-lite box-shadow  plans">
                        <h3 className="text-center">Gold</h3>
                        <h3 className="text-center"> <span> ₹ 6,999 /- </span></h3><br></br>
                        <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Vriddhi Site:1 </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">E Business Card </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Show Products/ Service </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Enquiry/ Leads </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Appointments </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">E-Greeting Cards </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>


                            
                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Support </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                            <div className="row margin-top-pos-10px">
                                <div className="col center-all">
                                  <button className="details-btn border-round-lite box-shadow" onClick={handlePaymentGold}>Select Plan</button>
                                </div>
                            </div>

                    </div>




                    <div className="col-sm-3 padding-md border-round-lite box-shadow  plans">
                        <h3 className="text-center">Silver</h3>
                        <h3 className="text-center"> <span> ₹ 4,999 /- </span></h3><br></br>
                        <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Vriddhi Site:1 </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">E Business Card </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20 col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Social Media Links </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        

                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Show Products/ Service </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                        
                      


                            <div className="center-all padding-md col-gap-20">
                            <div className="col-sm-10">
                                <h5  className="para-price">Support </h5>
                            </div>
                            <div className="col-sm-2">
                            <svg class="svg-inline--fa fa-circle-check fs-2" aria-hidden="true" 
                                  focusable="false" data-prefix="fas" data-icon="circle-check" 
                                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor"
                                   d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                        
                            </div>
                                  
                            </div>
                            <div className="row margin-top-pos-10px">
                                <div className="col center-all">
                                  <button className="details-btn border-round-lite box-shadow" onClick={handlePaymentSilver}>Select Plan</button>
                                </div>
                            </div>
                            
                    </div>
          
                    </div>
                </div>
            </div>
           
   
        <Footer/>
        </div>

        </div>
        </div>
        </div>
    )
}

