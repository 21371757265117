import Header from "./Header"
import './css/form.css'
import Sidebar from "./leftSidebar"
import Footer from "./Footer";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import * as ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './css/dashboard.css';
import './css/App.css';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FacebookIcon from '@mui/icons-material/Facebook';
import TextField from '@mui/material/TextField';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';














export default function Details(){


    const frontend= "https://evriddhi.com"
    const profileRef= useRef(null);
    const coverRef= useRef(null);
    const inputRef= useRef(null);
    const [user, setName]= useState('')
    const [businessDetails , setBusinessDetails]= useState({
        businessName:' ',
        businessContact:' ',
        businessEmail: ' ',
        whatsapp:' '
    })
    const [getCoverImage, GetCoverImage]= useState(' ');
    const [getProfileImage, GetProfileImage]= useState(' ');
   // const [getGalleryImage, GetGalleryImage]= useState('')
     const backend="https://api.evriddhi.com"









    //Set user business details

    useEffect(()=>{
        axios.get(backend+'/businessDetals')
        .then(res=> {

            setBusinessDetails(res)
            console.log(res.data)
            if(res.data.businessDetails.coverImage != null){
                GetCoverImage(backend+'/'+res.data.businessDetails.coverImage)
               
            }else{
                //console.log("hi2")
                GetCoverImage(backend+'/LOGOICON.png')
            }

            if(res.data.businessDetails.profileImage != null){
                GetProfileImage(backend+'/'+res.data.businessDetails.profileImage)
            }else{
                GetProfileImage(backend+'/LOGOICON.png')
            }
        })
        .catch(err=>  {console.log("error")})

        
    },[])





//Handling Cover images and profile images




const HandleCoverImage=(event)=>{
    console.log(event.target.files[0])
       // console.log("in handle cover image")
         const coverImageFile= new FormData();
         coverImageFile.append('image', event.target.files[0]);
         axios.post(backend+'/coverImage', coverImageFile)
         .then(res=> console.log(res.body))
         .catch(err=> console.log(err))
}


const handleProfileImage=(e)=>{
    const profileImageFile= new FormData();
    profileImageFile.append('image', e.target.files[0]);
    axios.post(backend+'/profileImage', profileImageFile)
    .then()
    .catch(err=> console.log(err))
}













   
   




// Getting basic details of user


    useEffect(()=>{
        axios.get(backend+'/dashboard', )
        .then(res=> {
            if(res.data.valid){
              setName(res.data.sessionData)
        }else{
     //       console.log(res.data)
        }
    })
    .catch(err=> console.log(err));
    },[]);












// handling basic details




   const [values, setBasicDetails]=useState({
        businesName: '',
        businessContact: '',
        businessEmail: '',
        businessDescription: '',
        GSTno: '',
        address:'',
        whatsapp:''
    });



  
     const handleSubmit=(event)=>{
        event.preventDefault();
      //  console.log('are the details getting saved?');
        axios.post(backend+'/userBusinessDetails', values)
        .then(alert('Business Details updated sucessfully'))
        .catch(err => console.log(err));

     }
   

    const handleBasicDetails=(event)=>{
        setBasicDetails(prev=> ({...prev,[event.target.name]:[event.target.value]
        }))
    }




// Updating Social media

const [socialMedia, setSocialMediaValues]= useState({
    facebook: '',
    instagram:'',
    youtube:'',
    linkedin: '',
    gmb: ''
});
 

const handleSocialMedia= (event)=>{
    setSocialMediaValues(prev=> ({...prev,[event.target.name]:[event.target.value]
    }))
}

const handleSocialMediaSubmit= ()=>{
    axios.post(backend+'/socialMediaUpdate', socialMedia)
    .then(res=>
     {//console.log(res.data)
        if(res.data.valid){
        alert('Social Links updated sucessfully')
     }else{
        alert('Unable to upload links')
     }
    }).catch("not updated")
}



// Updating Services

const [service, handleServiceInput]= useState({
    service1:'',
    description1:'',
    service2:'',
    description2:'',
    service3:'',
    description3:'',
    service4:'',
    description4:'',
    service5:'',
    description5:'',
    service6:'',
    description6:''
})


const handleServices=(event)=>{
    handleServiceInput(prev=> ({...prev,[event.target.name]:[event.target.value]
    }))
}

const sendServices=()=>{
    axios.post(backend+'/updateServices', service)
    .then(alert('Services updated sucessfully'))
    .catch(err=>{alert('Opps! Unable to update services')})
}










// Service Images Update


//First Service Image
const handleFirstServiceImage=(event)=>{
    const serviceImage1= new FormData();
    serviceImage1.append('image', event.target.files[0]);
    axios.post(backend+"/updateFirstServiceImage", serviceImage1)
    .then(res=> console.log(res))
    .catch(err=> console.log(err))
}


//Second Service Image
const handleSecondServiceImage=(event)=>{
    const serviceImage2= new FormData();
    serviceImage2.append('image', event.target.files[0]);
    axios.post(backend+"/updateSecondServiceImage", serviceImage2)
    .then(res=> console.log(res))
    .catch(err=> console.log(err))
}



//Third Service Image
const handleThirdServiceImage=(event)=>{
    const serviceImage3= new FormData();
    serviceImage3.append('image', event.target.files[0]);
    axios.post(backend+"/updateThirdServiceImage", serviceImage3)
    .then(res=> console.log(res))
    .catch(err=> console.log(err))
}


//Fourth Service Image
const handleFourthServiceImage=(event)=>{
    const serviceImage4= new FormData();
    serviceImage4.append('image', event.target.files[0]);
    axios.post(backend+"/updateFourthServiceImage", serviceImage4)
    .then(res=> console.log(res))
    .catch(err=> console.log(err))
}

//Fifth Service Image
const handleFifthServiceImage=(event)=>{
    const serviceImage5= new FormData();
    serviceImage5.append('image', event.target.files[0]);
    axios.post(backend+"/updateFifthServiceImage", serviceImage5)
    .then(res=> console.log(res))
    .catch(err=> console.log(err))
}


//Sixth Service Image
const handleSixthServiceImage=(event)=>{
    const serviceImage6= new FormData();
    serviceImage6.append('image', event.target.files[0]);
    axios.post(backend+"/updateSixthServiceImage", serviceImage6)
    .then(res=> console.log(res.body))
    .catch(err=> console.log(err))
}







const openInNewTab = (url) => {
            window.open(url, "_blank", "noreferrer");
}




// Getting gallery images

useEffect(()=>{
    axios.get(backend+"/getGalleryImage")
    .then(res=> {
        var leadTable;
        var i;
        const block=[];
       // console.log(res.data.result)
        for(i=1;i<7;i++){
            
            if(res.data.result[i]!=null){
                const imageLink= backend+'/'+res.data.result[i]

            leadTable=(
                <div className="row center  ">
                        <div className="col-sm-12  col-gap-20 ">
                        <div className="row padding-sm-x center padding-sm border-round-lite border-bottom-lite">
                                <div className="col-sm-2 ">
                                    <h5 className="leadData hide-on-phone">Image</h5>
                                </div>
                                <div className="col-sm-8 ">
                                   <a href={imageLink}> <h5 className="leadData">{backend}/{res.data.result[i]}</h5></a>
                                </div>
                                <div className="col-sm-1 d-flex justify-content-center">
                                    <Tooltip title="Delete">
                                       <IconButton>
                                         <DeleteIcon onClick={galleryItemDelete} />
                                       </IconButton>
                                     </Tooltip>
                                </div>
                               
                            </div>
                        </div>
                   </div>
            )  
            block.push(leadTable);

            const imgs= {
                img:res.data.result[i]
            }

            function galleryItemDelete(){
                axios.post(backend+'/galleryItemDelete', imgs)
                .then(res=>{
                    if(res.data.valid == true){
                        console.log("Image Deleted successfully")
                        alert("Image Deleted successfully")
                        window.location.reload();

                    }
                })
                .catch(console.log("unable to delete"))
            }
                        
        }}
        // Rendering
       const root= ReactDOM.createRoot(document.getElementById("leadData"));
       root.render(block)
    })
    .catch(err=> console.log(err));

    // <img onClick={galleryItemDelete} className="icon-0" src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" alt="Delete Icon"></img>

},[])








//  Gallery image Updation

const handleGalleryUpload=(event)=>{
    const galleryImage= new FormData();
    galleryImage.append('image', event.target.files[0]);
    axios.post(backend+"/galleryImageUpdate", galleryImage)
    .then(res=>{
        
        if(res.data.valid==false){
            alert("You cannot upload more images, Kindly delete some images and try again.......")
        }else{
            alert("Image Updated Successfully")
            window.location.reload();
        }
    })
    .catch()
}

// Handle image upload button

function handleButtonClick(){
    inputRef.current.click();
}


//Handle Cover Image Button

function handleCoverImageClick(){
    coverRef.current.click();
}


//Handle Profile Image


function handleProfileImageClick(){
    profileRef.current.click();
}











// Handle close button


const iframeElement= document.getElementById("iframe-container");

const handleCloseBtn= ()=>{
    iframeElement.style.display="none";
}

const handleQuickViewClick= ()=>{
    iframeElement.style.display="block";
}











// Display buttoned content


//display basicdetails

const basicDetails=()=>{
    const a= document.getElementById('basicDetails')
    a.style.display= "block";
    const b= document.getElementById('galleryImages')
    b.style.display= "none";
    const c= document.getElementById('Themes')
    c.style.display= "none";
    const d= document.getElementById('timings')
    d.style.display= "none";
    const e= document.getElementById('socialMedia')
    e.style.display= "none";
    const f= document.getElementById('services')
    f.style.display= "none";
}


const socialMediaBlock=()=>{
    const a= document.getElementById('basicDetails')
    a.style.display= "none";
    const b= document.getElementById('galleryImages')
    b.style.display= "none";
    const c= document.getElementById('Themes')
    c.style.display= "none";
    const d= document.getElementById('timings')
    d.style.display= "none";
    const e= document.getElementById('socialMedia')
    e.style.display= "block";
    const f= document.getElementById('services')
    f.style.display= "none";
}


const gallery=()=>{
    const a= document.getElementById('basicDetails')
    a.style.display= "none";
    const b= document.getElementById('galleryImages')
    b.style.display= "block";
    const c= document.getElementById('Themes')
    c.style.display= "none";
    const d= document.getElementById('timings')
    d.style.display= "none";
    const e= document.getElementById('socialMedia')
    e.style.display= "none";
    const f= document.getElementById('services')
    f.style.display= "none";
}

const theme=()=>{
    const a= document.getElementById('basicDetails')
    a.style.display= "none";
    const b= document.getElementById('galleryImages')
    b.style.display= "none";
    const c= document.getElementById('Themes')
    c.style.display= "block";
    const d= document.getElementById('timings')
    d.style.display= "none";
    const e= document.getElementById('socialMedia')
    e.style.display= "none";
    const f= document.getElementById('services')
    f.style.display= "none";
}


const timings=()=>{
    const a= document.getElementById('basicDetails')
    a.style.display= "none";
    const b= document.getElementById('galleryImages')
    b.style.display= "none";
    const c= document.getElementById('Themes')
    c.style.display= "block";
    const d= document.getElementById('timings')
    d.style.display= "none";
    const e= document.getElementById('socialMedia')
    e.style.display= "none";
    const f= document.getElementById('services')
    f.style.display= "none";
}



const services=()=>{
    const a= document.getElementById('basicDetails')
    a.style.display= "none";
    const b= document.getElementById('galleryImages')
    b.style.display= "none";
    const c= document.getElementById('Themes')
    c.style.display= "none";
    const d= document.getElementById('timings')
    d.style.display= "none";
    const e= document.getElementById('socialMedia')
    e.style.display= "none";
    const f= document.getElementById('services')
    f.style.display= "block";
}







   return( 
   <div className="container-fluid ">
   <div className="row bg-gray vh-100">
   <div className="col-sm-2 bg-white box-shadow border-round-lite static-section ">
           <Sidebar/>
       </div>
       <div className="col-sm-10 scrollable-section mobile-aligned-center">
           <div className="content">
           <Header />
           
           <div className="row noPad center  mobile-aligned-center col-gap-20 ">
            <div className="col-sm-12 ">
            <button id="details-btn" className="width-20 details-btn  border-round-lite" onClick={basicDetails}>Details</button>
             <button id="social-btn" className="width-20 details-btn  border-round-lite" onClick={socialMediaBlock}>Links</button>
             <button id="timings-btn" className="width-20 details-btn  border-round-lite" onClick={services}>Services</button>
             <button id="gallery-btn" className="width-20 details-btn  border-round-lite" onClick={gallery}>Gallery</button>
             <button id="theme-btn" className="width-20 details-btn  border-round-lite" onClick={theme}>Theme</button>
            </div>
           </div>
           <div className="iframe-container z-index-5 border-round-lite" id="iframe-container">
           <button className=" btn color-white" onClick={handleCloseBtn}>Close</button>
            <iframe className="iframe-site bg-white"  src={frontend+"/"+user.siteLink} title="description"></iframe>
          </div>


       <div className="row center  margin-pos-3">
           <div className="row" id="basicDetails" >
              <div className="col-sm-12 width-98 padding-md bg-white  box-shadow border-round-lite">
                <div className="row">
                    <div className="col-sm-6">
                <h1 className="margin-pos-2 margin-left">Basic Details</h1></div>
                <div className="col-sm-6 align-end ">
                <button className="quickView details-btn border-round-lite" onClick={handleQuickViewClick}>Quick View</button></div></div>
                <div className="col-sm-12 center">
               <div className="col-sm-6 border-right padding-md">
              
                    <TextField color="warning" required  id="business-name" className='login-input width-99' label="Business Name" variant="outlined" onChange={handleBasicDetails} type="businesName" name='businesName'/><br></br><br></br>
                    <TextField color="warning" required  id="business-email" className='login-input width-99' label="Business Email" variant="outlined" onChange={handleBasicDetails} type="businessEmail" name='businessEmail'/><br></br><br></br>
                    <TextField color="warning" required  id="busienss-contact" inputProps={{ maxLength: 10 }} className='login-input width-99' label="Business Contact" variant="outlined" onChange={handleBasicDetails} type="businessContact" name='businessContact'/><br></br><br></br>
                    <TextField color="warning" required  id="address" className='login-input width-99' label="Address" variant="outlined" onChange={handleBasicDetails} type="address" name='address'/><br></br><br></br>
               </div>
               <div className="col-sm-6 padding-md">
                    <TextField color="warning" required  id="business-description" className='login-input width-99' label="Business Description" variant="outlined" onChange={handleBasicDetails} type="businessDescription" name='businessDescription'  inputProps={{ maxLength: 500 }} /><br></br><br></br>
                    <TextField color="warning" required  id="whatsapp" className='login-input width-99' label="Whatsapp Number" variant="outlined" onChange={handleBasicDetails} type="whatsapp" name='whatsapp'/><br></br><br></br>
                    <TextField color="warning" required  id="gstNo" className='login-input width-99' label="GST Number" variant="outlined" onChange={handleBasicDetails} type="GSTno" name='GSTno'/><br></br><br></br>  
                    
                    <div className="row margin-top-pos-10px hide-on-phone">
                        <div className="col-sm-6  margin-top-pos-10px d-flex justify-content-center">
                             <div className="col-sm-7 ">
                                 <label className=" image-input"><span className="mobile-span">Cover Image</span></label>
                                 <p>(150x150)</p>
                                 <br></br>
                             </div>
                             <div className="col-sm-4 d-flex justify-content-center">
                                 <img className="cover-image-sidebar image-border border-round box-shadow  margin-right" onClick={handleCoverImageClick} src={getCoverImage} alt="image icon"></img>
                                 <input className="file-input" ref={coverRef}  type="file" name="coverImage" onChange={HandleCoverImage} placeholder="Cover Image" style={{display:"none"}} ></input><br></br>
                             </div>
                        </div>

                        <div className="col-sm-6 d-flex justify-content-center margin-top-pos-10px ">
                             <div className="col-sm-7 ">
                             <label className=" image-input" ><span  className="mobile-span">Profile Image</span></label>
                             <h6>(300x300)</h6>

                             <br></br>
                             </div>
                             <div className="col-sm-5 d-flex justify-content-start">
                                 <img className="cover-image-sidebar image-border border-round box-shadow  margin-right" onClick={handleProfileImageClick} src={getProfileImage} alt="image icon"></img>
                                 <input className="file-input"  type="file" name="profileImage" ref={profileRef} onChange={handleProfileImage} style={{display:"none"}}></input>
                             </div>
                        </div>
                        </div>

                    
                       
               </div>
               <div className="row margin-top-pos-10px d-flex justify-content-center margin-left hide-laptop">
                        <div className="col hide-laptop">
                             <div className="col ">
                                 <label className=" image-input"><span className="mobile-span">Cover Image</span></label>
                                 <br></br>
                             </div>
                             <div className="col ">
                                 <img className="cover-image-sidebar align-left image-border box-shadow  margin-right" onClick={handleCoverImageClick} src={getCoverImage} alt="image icon"></img>
                                 <input className="file-input" ref={coverRef}  type="file" name="coverImage" onChange={HandleCoverImage} placeholder="Cover Image" style={{display:"none"}} ></input><br></br>
                             </div>
                        </div>


                        <div className="col hide-laptop">
                             <div className="col">
                             <label className=" image-input" ><span  className="mobile-span">Profile Image</span></label><br></br>
                             </div>
                             <div className="col">
                                 <img className="cover-image-sidebar image-border  box-shadow  margin-right" onClick={handleProfileImageClick} src={getProfileImage} alt="image icon"></img>
                                 <input className="file-input"  type="file" name="profileImage" ref={profileRef} onChange={handleProfileImage} style={{display:"none"}}></input>
                             </div>
                        </div>
                        </div>
               </div>
               <div className="row">
                <div className="col center margin-pos-2 mobile-aligned-center top-margin-phone">
                    <button className="details-btn btn-submit border-round-lite" onClick={handleSubmit}>Submit</button>
                </div>
               </div>
           </div>
           </div>




           <div className="row center" id="socialMedia">
              <div className="col-sm-12 width-98 padding-md bg-white  box-shadow border-round-lite">
                <div className="row">
              <div className="col-sm-6">
                <h1 className="margin-pos-2 margin-left">Social Media Links</h1></div>
                <div className="col-sm-6 align-end ">
                <button className="quickView details-btn border-round-lite" onClick={handleQuickViewClick}>Quick View</button></div></div>
                <div className="col-sm-12 center">
               <div className="col-sm-6 border-right padding-md">               

                <form >
                    <FacebookIcon color="warning"></FacebookIcon>
                    <label className="margin-top-pos-10px"> Facebook</label><br></br><br></br>
                    <TextField color="warning" required  id="facebook-link" className='login-input width-99' label="Facebook Link" variant="outlined" onChange={handleSocialMedia} name='facebook'  /><br></br><br></br>
                   <InstagramIcon color="warning"></InstagramIcon>
                    <label className="margin-top-pos-10px">Instagram</label><br></br><br></br>
                    <TextField color="warning" required  id="instagram-link" className='login-input width-99' label="Instagram Link" variant="outlined" onChange={handleSocialMedia} name='instagram'   />         
                     
                     <br></br><br></br>
                    <InstagramIcon color="warning"></InstagramIcon>
                    <label className="margin-top-pos-10px">GMB Link</label><br></br><br></br>
                    <TextField color="warning" required  id="gmb-link" className='login-input width-99' label="GMB Link" variant="outlined" onChange={handleSocialMedia} name='gmb'   />     

                </form>
               </div>
               <div className="col-sm-6 padding-md">
                  <YouTubeIcon color="warning"></YouTubeIcon>
                    <label className="margin-top-pos-10px"> YouTube</label><br></br><br></br>
                    <TextField color="warning" autoFocus required  id="youtube-link" className='login-input width-99' label="Youtube Link" variant="outlined" onChange={handleSocialMedia} name='youtube'   /><br></br><br></br>
                   <LinkedInIcon color="warning"></LinkedInIcon>
                    <label className="margin-top-pos-10px">Linkedin</label><br></br><br></br>
                    <TextField color="warning" autoFocus required  id="instagram-link" className='login-input width-99' label="Instagram Link" variant="outlined" onChange={handleSocialMedia} name='linkedin'   />                   

               </div>
               </div>
               <div className="row">
                <div className="col center margin-pos-2 mobile-aligned-center ">
                    <button className="details-btn btn-submit border-round-lite" onClick={handleSocialMediaSubmit}>Submit</button>
                </div>
               </div>
           </div>
           </div>






           <div className="row-fluid  center" id="galleryImages">
              <div className="col-sm-12 padding-md bg-white  box-shadow border-round-lite">

                <div className="row">
                    <div className="col-sm-6 ">
                      <h1 className="margin-pos-2 margin-left">Gallery Images</h1>
                    </div>
                    <div className="col-sm-6 align-end">
                        <button className="details-btn btn-submit border-round-lite box-shadow"  onClick={handleButtonClick}>Upload Image</button>
                        <input className="file-input"  type="file" name="galleryImage" ref={inputRef} onChange={handleGalleryUpload} style={{display:'none'}} ></input>
                    </div>
                </div>

                <div className="row padding-md  margin-top-pos-5 ">
                    <div className="col-sm-12 box-shadow  col-gap-20 border-round-lite">
                        <div className="row bg-red justify-content-center d-flex padding-sm bg-grey-dark border-round-lite ">
                            
                            <div className="col-sm-2 ">
                                <h5 className="table-h5 hide-on-phone" >Type</h5>
                            </div>
                            <div className="col-sm-8 ">
                                <h5 className="table-h5">Image Link</h5>
                            </div>
                            <div className="col-sm-1">
                                <h5 className="table-h5">Delete</h5>
                            </div>
                            
                            
                        </div>
                        <div className="row" id="">
                            <div  id="leadData"></div>
                        </div>
               </div>
               </div>
               <div className="row">
                
               </div>
           </div>
           </div>








           <div className="row center" id="Themes">
              <div className="col-sm-12  width-98 padding-md bg-white  box-shadow border-round-lite">
               
            <div className="row">
              <div className="col-sm-6">
                <h1 className="margin-pos-2 margin-left">Themes</h1></div>
                <div className="col-sm-6 align-end ">
                <button className="quickView details-btn border-round-lite" onClick={handleQuickViewClick}>Quick View</button></div></div><br></br><br></br><br></br>
            <div className="container  mobile-aligned-center col-gap-20">
               <div className="row col-gap-20-2  mobile-aligned-center">
                <div className="col">
                    <button className="padding-md border-round-lite box-shadow theme-btn ">
                       <iframe className="  border-round-lite"  src="https://evriddhi.com/Podosphere%20Technologies" title="description"></iframe>
                       <h5 className="white">Template Basic</h5>
                    </button><br></br>
                </div>


               
                </div></div>

               
           </div>
           </div>






    

           <div className="row  center" id="timings">
              <div className="col-sm-12 width-98 padding-md bg-white  box-shadow border-round-lite">
                <h1 className="margin-pos-2 margin-left">Timings</h1>
                
                    
           </div>
           </div>











           <div className="row  center" id="services">
              <div className="col-sm-12 width-98 padding-md bg-white  box-shadow border-round-lite">
              <div className="row">
              <div className="col-sm-6">
                <h1 className="margin-pos-2 margin-left">Products/Services</h1></div>
                <div className="col-sm-6 align-end ">
                <button className="quickView details-btn border-round-lite " onClick={handleQuickViewClick}>Quick View</button></div></div>
               <div className="col-sm-12 center">
               <div className="col-sm-6 border-right padding-md">
                
                <TextField color="warning" required  id="service1" className='login-input width-99' inputProps={{ maxLength: 40 }}  label="Service" variant="outlined" onChange={handleServices} name='service1'  /><br></br><br></br>
                <TextField color="warning" required  id="description1" className='login-input width-99' inputProps={{ maxLength: 1000 }}  label="Description" variant="outlined" onChange={handleServices} name='description1'  /><br></br><br></br>

                    <div className="row center">
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-8 d-flex justify-content-end">
                          <input className="file-input"  type="file" name="profileImage" onChange={handleFirstServiceImage}></input>
                        </div>
                    </div><br></br>

                    <TextField color="warning" required  id="service2" className='login-input width-99' label="Service" inputProps={{ maxLength: 40 }}  variant="outlined" onChange={handleServices} name='service2'  /><br></br><br></br>
                  <TextField color="warning" required  id="description2" className='login-input width-99' inputProps={{ maxLength: 1000 }}  label="Description" variant="outlined" onChange={handleServices} name='description2'  /><br></br><br></br>
                  <div className="row center">
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-8 d-flex justify-content-end">
                          <input className="file-input" type="file" name="profileImage" onChange={handleSecondServiceImage}></input>
                        </div>
                    </div><br></br>
                    <TextField color="warning" required  id="service3" className='login-input width-99' inputProps={{ maxLength: 40 }}  label="Service" variant="outlined" onChange={handleServices} name='service3'  /><br></br><br></br>
                    <TextField color="warning" required  id="description3" className='login-input width-99' inputProps={{ maxLength: 1000 }}  label="Description" variant="outlined" onChange={handleServices} name='description3'  /><br></br><br></br>

                    <div className="row center">
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-8 d-flex justify-content-end">
                          <input className="file-input"  type="file" name="profileImage" onChange={handleThirdServiceImage}></input>
                        </div>
                    </div><br></br>
               </div>
               <div className="col-sm-6 padding-md">
                <TextField color="warning" required  id="service4" className='login-input width-99' label="Service" inputProps={{ maxLength: 40 }}  variant="outlined" onChange={handleServices} name='service4'  /><br></br><br></br>
                <TextField color="warning" required  id="description4" className='login-input width-99'  inputProps={{ maxLength: 1000 }}  label="Description" variant="outlined" onChange={handleServices} name='description4'  /><br></br><br></br>
                <div className="row center">
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-8 d-flex justify-content-end">
                          <input className="file-input"  type="file" name="profileImage" onChange={handleFourthServiceImage}></input>
                        </div>
                    </div><br></br>
                    <TextField color="warning" required  id="service5" className='login-input width-99' label="Service"inputProps={{ maxLength: 40 }}  variant="outlined" onChange={handleServices} name='service5'  /><br></br><br></br>
                    <TextField color="warning" required  id="description5" className='login-input width-99' inputProps={{ maxLength: 1000 }}  label="Description" variant="outlined" onChange={handleServices} name='description5'  /><br></br><br></br>
                     <div className="row center">
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-8 d-flex justify-content-end">
                          <input className="file-input"  type="file" name="profileImage" onChange={handleFifthServiceImage}></input>
                        </div>
                    </div><br></br>
                    <TextField color="warning" required  id="service6" className='login-input width-99' inputProps={{ maxLength: 40 }}  label="Service" variant="outlined" onChange={handleServices} name='service6'  /><br></br><br></br>
                   <TextField color="warning" required  id="description6" className='login-input width-99' inputProps={{ maxLength: 1000 }}  label="Description" variant="outlined" onChange={handleServices} name='description6'  /><br></br><br></br>
                   <div className="row center">
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-8 d-flex justify-content-end">
                          <input className="file-input"  type="file" name="profileImage" onChange={handleSixthServiceImage}></input>
                        </div>
                    </div><br></br>
                   
               </div>
               
               </div>
               <div className="row">
                <div className="col center margin-pos-2 mobile-aligned-center ">
                    <button className="details-btn btn-submit border-round-lite" onClick={sendServices}>Submit</button>
                </div>
               </div>
           </div>
           </div>



    

           
    

       <div className="row  margin-pos-2 center ">
           <div className="col-sm-12 width-99-desktop">
           <div className="box-shadow border-round-lite padding-md bg-red">
              <h4>Gateway to your online presence</h4>
              <p>Create an AI-generated website with relevant local keywords and 
                 SEO optimization in 10 minutes. Implement local SEO strategies, 
                 including Google My Business, citations, and content updates, 
                 to rank in the area within 30 days.
              </p>
              <p ><a href="" className="link-underline">Subscribe Now</a> </p>
           </div>
           </div>
       </div>
       </div>
       <Footer/>
      
       </div>

       </div>
       </div>
       </div>
   )
}






