import React from "react";
import './homepage.css'
import Footer from "./footer"
import Header  from './header';





export default function Return(){
    return(
        <div className="main-container">

            <div className="container">
                <div className="row">
                    <div className="col">
                        <Header></Header>
                    </div>
                </div>
            </div>

            <div className="container">
            <div className="row">
                <div className="col">
                    <h1><br></br>Refund and Cancellation Policy<br></br><br></br></h1>
                    <p>
                        
                            <b>Effective Date: 13th July 2024</b><br></br>
                            <br></br>
                            Welcome to the website of Evriddhi Tech Private Limited ("Evriddhi," "we," "us," or "our"). These Terms and Conditions ("Terms") govern your access to and use of our website at [Your Website URL] (the "Website") and any services provided therein (collectively, the "Service"). By accessing or using our Website, you agree to be bound by these Terms.                            
                            <br></br><br></br>Subscription and Billing:<br></br>
                    </p>
                </div>
            </div>
            </div>



            <div className="container">
            <div className="row">
                <div className="col">
                <br></br><br></br>
                            <h4>‍1.1 Subscription Plans:</h4>
                            <p>‍We offer various subscription plans for our Service, each with specific features and durations. The details of each plan, including pricing and billing cycles, are clearly outlined on our website and during the subscription process.</p>
                   
                </div>
            </div>
            </div>



            <div className="container">
            <div className="row">
                <div className="col">
                <br></br><br></br>
                            <h4>‍1.2 Billing and Payment:</h4>
                            <p>‍Billing occurs according to the selected subscription plan and cycle (e.g., monthly, annually). Payments are processed through our designated payment processor, and we do not store or have access to your payment card details.</p>
                   
                </div>
            </div>
            </div>

           






            
            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>Refunds</h4>
                        <br></br><br></br>
                        <h4>‍2.1 Refund Eligibility:</h4>
                        <p>‍We strive to provide a high-quality Service, and customer satisfaction is important to us. Refunds may be considered in the following circumstances:
                           <br></br><br></br>Technical Issues: If you encounter significant technical issues that prevent you from accessing or using the Service within the initial period after purchase, you may be eligible for a refund.</p>
                    </div>
                </div>
            </div>








            <div className="container">
                <div className="row">
                    <div className="col">
                        <Footer></Footer>
                    </div>
                </div>
            </div>
        </div>




    );}