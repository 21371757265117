import Header from "./Header"
import Sidebar from "./leftSidebar"
import Footer from "./Footer";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './css/dashboard.css';
import './css/App.css';





export default function AdminDashboard(){

    // variables user is the current session user and navigate is the page they need to navigate to
    const backend="https://api.evriddhi.com"
    const [businessDetails, setBusinessDetails]= useState({
        businessName:' ',
        businessContact:' ',
        businessEmail: ' ',
        whatsapp:' '
    })
    const [user, setName]= useState('')
    const navigate= useNavigate();
    axios.defaults.withCredentials=true;
    const [leadCount, setLeadCount]=useState('');
    const [galleryImages, setGalleryImages]= useState('');
    const [serviceCount, SetServiceCount]= useState('');



    // calling api to display details on dashboard 


    useEffect(()=>{
        axios.get(backend+'/dashboard')
        .then(res=> {
            if(res.data.valid){
              setName(res.data.sessionData)
        }else{
            console.log(res.data)
        }


        axios.get(backend+"/serviceCount")
        .then(res=>{
            if(res.data.valid){
                SetServiceCount(res.data.count)
            }else{
                SetServiceCount(0)
            }
        }).catch(err=>{
            console.log("Api refused to connect")
        })
    })
    .catch(err=> console.log(err));




    axios.get(backend+'/getLeads').then(res=>{
        if(res.data.leadDetails== null){
            setLeadCount('0');
        }else{
        setLeadCount(res.data.leadDetails.length);}
        console.log(res.data.leadDetails.length)
    }).catch();


    //get business description
axios.get(backend+'/businessDetals').then(res=> {
    if( res.data.businessDetails.businessName== null){
    }else{
        setBusinessDetails(res.data.businessDetails)
    }
   }).catch(err=> {console.log("error")});



   var index=0
   axios.get(backend+'/getGalleryImage')
   .then(res=>{
    for(let i=1;i<7;i++){
        if(res.data.result[i]!=null){
            index++;
        }
    }

    setGalleryImages(index)
   }).catch(console.log("Failed to call Gallery Api"))
 },[]);







  








    // jsx code for the display page

   return( 
   <div className="container-fluid ">
    <div className="row bg-grey vh-100">
        
        <div className="col-sm-2 bg-white box-shadow border-round-lite static-section ">
            <Sidebar/>
        </div>
        <div className="col-sm-10  scrollable-section mobile-aligned-center">
            <div className="content">
            <Header />
            <div className="container center margin-pos-3">
            <div className="row width-99  center mobile-aligned-center col-gap-20 ">
            <div className="col-sm-3 bg-white  width-20-desktop width-20-phone padding-y box-shadow border-round-lite">
                    <h1 className="total-leads dash-number">{leadCount}</h1>
                    <h4 className="dash-header"> Enquiries</h4>
                    <p className="para-desktop-box dash-para">Go to Enquiry/Leads Section</p>
            </div>

            <div className="col-sm-3 bg-white nomar width-20-desktop  width-20-phone padding-y box-shadow border-round-lite">
                    <h1 className="total-leads dash-number">{serviceCount}</h1>
                    <h4 className="dash-header">Services</h4>
                    <p className="para-desktop-box dash-para">Number Of Active Services</p>
            </div>

            <div className="col-sm-3 bg-white nomar width-20-desktop  width-20-phone padding-y box-shadow border-round-lite">
                    <h1 className="total-leads dash-number">{galleryImages}</h1>
                    <h4 className="dash-header">Images</h4>
                    <p className="para-desktop-box dash-para">Number Of Images Uploaded</p>
            </div>

            <div className="col-sm-3 bg-white nomar width-20-desktop  width-20-phone padding-y box-shadow border-round-lite">
                    <h1 className="total-leads dash-number">0</h1>
                    <h4 className="dash-header">Appointment</h4>
                    <p className="para-desktop-box dash-para">Go To Appointment's Section</p>
            </div>
        
            </div>
            </div>

            

        <div className="row  margin-pos-2 center ">
            <div className="col-sm-12 width-98">
            <div className="box-shadow border-round-lite padding-md bg-red">
               <h4>Gateway to your online presence</h4>
               
               <p>Create an AI-generated website with relevant local keywords and 
                  SEO optimization in 10 minutes. Implement local SEO strategies, 
                  including Google My Business, citations, and content updates, 
                  to rank in the area within 30 days.
               </p>
               <p ><a href="" className="link-underline">Subscribe Now</a> </p>
            </div>
            </div>
        </div>


        <div className="row padding-sm-x  center col-gap-40 ">
            <div className="col-sm-5 padding-sm-x margin-pos-3  padding-left bg-white box-shadow border-round-lite   padding-md margin-top-pos-10px" >
            <h3 className="left-align">User Details</h3><br></br>
                      <p><span>Username:</span> {user.name}</p>
                      <p><span>Email:</span> {user.email}</p>
                      <p><span>Phone Number:</span> {user.phoneno}</p>
                      <p><span>Subscription:</span> {user.subscriptionPlan}</p>
                        
            </div>
            <div className="col-sm-5 margin-pos-3  col-sm-6 bg-white box-shadow border-round-lite margin-top-pos-10px  padding-md">
                <h2>Business Info</h2><br></br>
                 <p><span>Business Name:</span> {businessDetails.businessName}</p>
                 <p><span>Business Contact:</span> {businessDetails.businessContact}</p>
                 <p><span>Business Email:</span> {businessDetails.businessEmail}</p>
                 <p><span>Business Whatsapp:</span>{businessDetails.whatsapp} </p>          
            </div>
        </div>
        <Footer/>
        </div>

        </div>
        </div>
        </div>
    )
}

