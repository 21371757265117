// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input{
    border-radius: 5px;
    height: 50px;
    width: 70%;
    margin-bottom: 1%;
    margin-top: 1%;
    padding-left: 20px;
    text-align: start;
}
label{
    font-size: 20px;
    margin-right: 20%;
}

.input-box-Description{
    height: 100px;
    
}


.email{
    margin-bottom: 5%;
}



`, "",{"version":3,"sources":["webpack://./src/adminDashboard/css/form.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;;AAEjB;;;AAGA;IACI,iBAAiB;AACrB","sourcesContent":["input{\n    border-radius: 5px;\n    height: 50px;\n    width: 70%;\n    margin-bottom: 1%;\n    margin-top: 1%;\n    padding-left: 20px;\n    text-align: start;\n}\nlabel{\n    font-size: 20px;\n    margin-right: 20%;\n}\n\n.input-box-Description{\n    height: 100px;\n    \n}\n\n\n.email{\n    margin-bottom: 5%;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
