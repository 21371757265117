
import './App.css';
import  Login from './Login';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Signup from './Signup';
import AdminDashboard from './adminDashboard/adminDashboard';
import Details from './adminDashboard/details'
import axios from "axios";
import React, { useEffect, useState } from "react";
import Template_01 from './Templates/Template_01';
import Settings from './adminDashboard/settings';
import Enquiries from './adminDashboard/enquiries';
import Appointments from './adminDashboard/appointment';
import Plans from './adminDashboard/plans';
import Homepage from './homepage';
import PrivacyPolicy from './privacyPolicy';
import Terms from './terms';
import Shipping from './shippingpolicy';
import Return from './returns';








export default function App() {


  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Homepage />} />
      <Route path='/login' element={<Login />}/>
      <Route path='/signup' element={<Signup />}/>
      <Route path='/admin/Dashboard' element={<AdminDashboard />}></Route>
      <Route path='/admin/Details' element={<Details />}></Route>
      <Route path='/:userId'  element={<Template_01 />}></Route>
      <Route path='/admin/settings'  element={< Settings/>}></Route>
      <Route path='/admin/enquiries'  element={<Enquiries/>}></Route>
      <Route path='/admin/appointments'  element={<Appointments/>}></Route>
      <Route path='/admin/plans&pricing'  element={<Plans/>}></Route>
      <Route path='/privacyPolicy' element={<PrivacyPolicy></PrivacyPolicy>}/>
      <Route path='/termsandconditions' element={<Terms/>}></Route>
      <Route path='/shippingPolicy' element={<Shipping></Shipping>}></Route>
      <Route path='/return' element={<Return></Return>}></Route>

    </Routes>
    </BrowserRouter>
 )
 //     

}
