import React, { useEffect, useState } from "react";
import './css/sidebar.css';
import profile_Icon from "./images/profile_Icon.png"
import axios from "axios";
import {useNavigate} from "react-router-dom";
import logoCropped from "./images/logoCropped.png"
import LOGOICON from './images/LOGOICON.png'
import Dashboard from './images/Dashboard.png'
import settings from './images/settings.png'
import enquiry from './images/enquiry.png'
import id from './images/id.png'
import logout from './images/logout.png'
import { ReactTyped } from "react-typed";
import 'bootstrap/dist/css/bootstrap.css';
import './css/dashboard.css';
import './css/App.css';
import { Carousel } from 'react-bootstrap';










export default function Sidebar(){
    const backend="https://api.evriddhi.com"
    const [user, setName]= useState('')
    const navigate= useNavigate();
    const [adminImage, setAdminImage]=useState('')
    axios.defaults.withCredentials=true;


    useEffect(()=>{
       // setAdminImage(backend+'/LOGOICON.png')

        axios.get(backend+'/dashboard')
        .then(res=> {
            if(res.data.valid){
              setName(res.data.sessionData)
              console.log(res.data.sessionData)
        }else{
            console.log(res.data)
        }
  console.log("getting called")
        if(res.data.sessionData.adminImage== null){
            setAdminImage(backend+'/LOGOICON.png')
         }else{
            setAdminImage(backend+'/'+res.data.sessionData.adminImage)
         }
        
    })
    .catch(err=>setAdminImage(backend+'/LOGOICON.png')
);
    },[]);


    const dash=()=>{
        navigate('/admin/Dashboard')
    }

    const vriddhiSite=()=>{
        navigate('/admin/details')
    }

    const enquiries=()=>{
        navigate('/admin/enquiries')
    }

    const appointments=()=>{
        navigate('/admin/appointments')
    }

    const plans=()=>{
        navigate('/admin/plans&pricing')
    }

    const setting=()=>{
        navigate('/admin/settings')
    }



    //logout

    const logouts=async ()=>{
        const response = await axios(backend+"/logout", { method: "POST"});
        navigate('/login');
    }



    // Menu Handle

    const openmenu=()=>{
        document.getElementById('phone-show').style.display="block"
    }

    const closemenu=()=>{
        document.getElementById('phone-show').style.display="none"

    }






    return(<>
   
        <div className="row-fluid hide-laptop">
                <div className="col bg-grey">
        <div className='row-fluid  bg-red height-50 d-flex '>
            <div className='col d-flex justify-content-center'>
            <Carousel interval={2500} controls={false} indicators={false}>
                 <Carousel.Item >
                    <div className="carousel-text text-end d-flex justify-content-end">
                      <p>Create Your DIY Website Just 5 Min.</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-text  text-end d-flex justify-content-end ">
                      <p >Get Instant Enquiries On Vriddhi Board.</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-text text-end d-flex justify-content-end">
                      <p>Get Your Business Online in Just 3 Steps.</p>
                    </div>
                  </Carousel.Item>
                </Carousel>
            </div>
    </div>
      
                </div>
            </div>

            <div className="row hide-laptop">
                <div className="col hide-laptop">
                    <img className="logo-full hide-laptop" src={logoCropped} alt="image"></img>
                </div>
                <div className="col d-flex justify-content-end">
                    <button className="btn-sidebar hide-laptop" onClick={openmenu}>Menu</button>
               </div>
            </div>
        <div className="container-fluid phone-sidebar" id="phone-show">

            
            
        <div className="row padding-sm ">
          <div className="col-sm-12 d-flex justify-content-end align-items-center">
          <button className="btn-sidebar hide-laptop" onClick={closemenu}>X</button></div>
          <div className="col d-flex justify-content-start">
          <img className="evriddhiLogo" src={logoCropped} alt="evriddhi logo"></img></div>

        </div> 
        <hr className=""></hr>
        <div className='row hide-on-phone center padding-sm-y'>
            <div className='col-sm-3 center  hide-on-phone' id="adminImage">
                  <img className="cover-image-sidebar border-round box-shadow margin-top-neg-10" src={adminImage} alt="image icon"></img>
            </div>
            <div className='col-sm-8  '>
                    <h5 className='margin-top-pos-10 padding-phone'><a href="https://evriddhi.com/admin/dashboard">{user.name}</a></h5>
                    <p className='margin-top-neg-10 hide-on-phone' >{user.phoneno}</p>
                    
            </div>
        </div>

       
        <hr></hr>
       
            <div className="row padding-sm-y"> 

                <div className="col">
                <button className="btn-sidebar  d-flex " onClick={dash}>
                    <img className="icon-0 padding-sm-x" src={Dashboard} alt="icon"></img>
                    <p>Dashboard</p>
                 </button>
                </div>
            </div>
            <div className="row padding-sm-y"> 
                <div className="col">
                 <button className="btn-sidebar  d-flex " onClick={vriddhiSite}>
                    <img className="icon-0 padding-sm-x" src={id} alt="icon"></img>
                    <p>vriddhi Site</p>
                 </button>
                </div>
            </div>
            
            <div className="row padding-sm-y"> 
                <div className="col">
                 <button className="btn-sidebar  d-flex " onClick={plans}>
                    <h1 className="padding-sm-x  margin-top-neg-10px">₹</h1>
                    <p >Plans & Pricing</p>
                 </button>
                </div>
            </div>

            <div className="row padding-sm-y"> 
                <div className="col">
                 <button className="btn-sidebar  d-flex " onClick={enquiries}>
                    <img className="icon-03 padding-sm-x" src={enquiry} alt="icon"></img>
                    <p>Enquiries/ Leads</p>
                 </button>
                </div>
            </div>
            
         

            <div className="row padding-sm-y"> 
                <div className="col">
                 <button className="btn-sidebar  d-flex " onClick={setting}>
                    <img className="icon-02 padding-sm-x" src={settings} alt="icon"></img>
                    <p>settings</p>
                 </button>
                </div>
            </div>
            
            <div className="row padding-sm-y"> 
                <div className="col">
                 <button className="btn-sidebar  d-flex " onClick={logouts}>
                    <img className="icon-02 padding-sm-x" src={logout} alt="icon"></img>
                    <p>Logout</p>
                 </button>
                </div>
            </div>
            
            
        </div>
        </>
        
    )


   // <div className="row padding-sm-y"> 
   // <div className="col">
   //  <button className="btn-sidebar  d-flex " onClick={appointments}>
   //     <img className="icon-03 padding-sm-x" src={appointment} alt="icon"></img>
   //     <p>Appointments</p>
   //  </button>
   // </div>
//</div>

}