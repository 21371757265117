import Header from '../adminDashboard/Header'
import './Templates_css/Template_01.css'
import './Templates_css/slider.css'
import React, { useEffect, useState } from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import calendarimage from './images/template01/calendarimage.png'
import Instagram from "./images/template01/Instagram.png"
import Meta from "./images/template01/Meta.png"
import linkedin from "./images/template01/linkedin.png"
import Youtube from "./images/template01/Youtube.png"
import * as ReactDOM from 'react-dom/client';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/esm/CarouselItem';




export default function Template_01(){  
 



   const {userId}=useParams();
   const [img1, Img1]=useState('');
   const [img2, Img2]=useState('');
   const [img3, Img3]=useState('');
   const [img4, Img4]=useState('');
   const [img5, Img5]=useState('');
   const [img6, Img6]=useState('');

   // user details
   const [user, setUser]=useState('');


   // user business details
   const [businessDetails, setBusinessDetails]= useState('')
   const [getcoverImage, GetCoverImage]= useState('')
   const [getProfileImage, GetProfileImage]= useState('')


   //user social media
   const [socialMedia, setSocialMedia]= useState('');



   //Backend

   const backend="https://api.evriddhi.com"



   //user service details
   const [serviceDetails, setServiceDetails]= useState('')






   const userID={
      name:userId
   }

   var useremail={
      email:''
   }

   useEffect(() => {
      
       // Getting user details
   console.log(userId)
   axios.post(backend+'/getUserDetails', userID).then(res=> {
      setUser(res.data.userDetails)
    //  console.log(res.data.userDetails)
    
      useremail.email= res.data.userDetails.email
      socialDetails();
      businessDescription();
      serviceDetailsFunction();
   }).catch();

   }, []); 




   

   function socialDetails(){
      // Getting Socimedia details
     // console.log(useremail)

       axios.post(backend+'/getSocialMediaLinks', useremail).then(res=> {
         setSocialMedia(res.data.socialdetails)
             console.log(res.data.socialdetails)
          }).catch(err=> {console.log("error")});
   }


 // Getting business details
   function businessDescription(){
     
       axios.post(backend+'/getBusinessDetails', useremail).then(res=> {
             setBusinessDetails(res.data.businessDetails)
             if(res.data.businessDetails.coverImage == null){
               GetCoverImage(backend+'/LOGOICON.png')
           }else{
               GetCoverImage(backend+"/"+res.data.businessDetails.coverImage)
           }

           if(res.data.businessDetails.profileImage == null){
               console.log(res.data.businessDetails.profileImage)
               GetProfileImage(backend+'LOGOICON.png')
           }else{
               GetProfileImage(backend+"/"+res.data.businessDetails.profileImage)
           }



           axios.post(backend+"/getGalleryImageTemplate", res.data.businessDetails)
           .then(res=> 
             { 
               if(res.data.valid== true){
                  Img1(backend+"/"+Object.entries(res.data.result)[1][1])
                  Img2(backend+"/"+Object.entries(res.data.result)[2][1])
                  Img3(backend+"/"+Object.entries(res.data.result)[3][1])
                  Img4(backend+"/"+Object.entries(res.data.result)[4][1])
                  Img5(backend+"/"+Object.entries(res.data.result)[5][1])
                  Img6(backend+"/"+Object.entries(res.data.result)[0][1])
                  console.log(Object.entries(res.data.result)[0][1]+ " "+Object.entries(res.data.result)[2][1])
                  
               }
}
)
   .catch(err=> console.log(err))})
   .catch(err=> {console.log("error")});
   }


   //Getting service details

   function serviceDetailsFunction(){
       //console.log(useremail)

      axios.post(backend+'/serviceDetails', useremail).then(res=> {
         setServiceDetails(res.data.serviceDescription)
         }).catch(err=> {console.log("error")});
  }

  

  
const today= new Date();



  // Handle enquiry form
  const [lead, setLeadDetails]= useState({
   email:'',
   leadName:'',
   leadPhoneNo:'',
   leadEmailId:'',
   Date: today.getDate()+'-'+today.getMonth()+'-'+today.getFullYear(),
  })

  const handleLeadDetails=(event)=>{
   lead.email= user.email
   setLeadDetails(prev=> ({...prev,[event.target.name]:[event.target.value]}))
  }

  const handleLeadFormSubmission=()=>{
   console.log(lead.email)
   axios.post(backend+'/leadForm', lead)
   .then(result=>
      {
         console.log(result.data)

      }
   )
   .catch(err=> console.log("unable to send mail"))
  }


   



if(user.siteLink==null){
     return(
       <div>User Not Found</div>)
    }else{



       //Hiding Services if not updated
if(serviceDetails.service1!=null){
   const element= document.getElementById("service1");
   element.style.display="block";
}

if(serviceDetails.service2!= null){
   const element= document.getElementById("service2");
   element.style.display="block";
}
if(serviceDetails.service3!= null){
   const element= document.getElementById("service3");
   element.style.display="block";
}
if(serviceDetails.service4!= null){
   const element= document.getElementById("service4");
   element.style.display="block";
}
if(serviceDetails.service5!= null){
   const element= document.getElementById("service5");
   element.style.display="block";
}

if(serviceDetails.service6!= null){
   const element= document.getElementById("service6");
   element.style.display="block";
}

  if(socialMedia.gmb!=null){
   const gmb= document.getElementById("gmbframe");
   gmb.style.display= "block";
}
       
       

      return(

         <>

         <div className='container-fluid outer-container'>
            <div className='container-fluid inner-container'>
           
               <div className='row'>
                  <div className='col'>
                  <img className='profile-image' src={getProfileImage} alt='profile image'></img>
                  </div>
                
               </div>
               <div className='row align-center'>
                  <img className='cover-image box-shadow-outer-template1' src={getcoverImage} alt='cover image'></img>
               </div>
               <div className='row align-center'>
                  <h2 className='align-center margin-top'>{businessDetails.businessName}</h2>
                  <h4 className='align-center margin-bottom width-80 text-center'>{businessDetails.businessDescription}</h4>
               </div>
               <div className='row align-center'>
                  <div className='col align-center content-gap'>
                  <a href={socialMedia.facebook}><img className='imageIcon box-shadow-outer-template1' src={Meta} alt='call icon'></img></a>
                  <a href={socialMedia.instagram}><img className='imageIcon box-shadow-outer-template1' src={Instagram} alt='call icon'></img></a>
                  <a href={socialMedia.youtube}><img className='imageIcon box-shadow-outer-template1' src={Youtube} alt='call icon'></img></a>
                  <a href={socialMedia.linkedin}><img className='imageIcon box-shadow-outer-template1  round-image' src={linkedin} alt='call icon'></img></a>
               </div>

               <div className='row container-margin'>
                  <div className='col'>
                     <h3 className='text-align '>Services</h3>
                     <p className='text-align margin-bottom'>Best in class Services for your needs</p>
                  </div>
               </div>
               
               <div className='row align-center content-gap margin-bottom'>
                  <div id='service1' className='col-sm-5 service-container box-shadow-template1 pad border-radius'>
                     <img className='service-img' src={backend+"/"+serviceDetails.img1} alt='services'></img>
                     <h4 className='text-align '> {serviceDetails.service1}</h4>
                     <p className='text-align '>{serviceDetails.description1}</p>
                  </div>
                  <div id='service2' className='col-sm-5 service-container box-shadow-template1 pad border-radius'>
                  <img className='service-img' src={backend+"/"+serviceDetails.img2} alt='services'></img>
                     <h4 className='text-align '>{serviceDetails.service2}</h4>
                     <p className='text-align '>{serviceDetails.description2}</p>
                  </div>
                  <div id='service3' className='col-sm-5 service-container box-shadow-template1 pad border-radius'>
                  <img className='service-img' src={backend+"/"+serviceDetails.img3} alt='services'></img>
                     <h4 className='text-align '>{serviceDetails.service3}</h4>
                     <p className='text-align '>{serviceDetails.description3}</p>
                  </div>
               
                  <div id='service4' className='col-sm-5 service-container box-shadow-template1 pad border-radius'>
                     <img className='service-img' src={backend+"/"+serviceDetails.img4} alt='services'></img>
                     <h4 className='text-align '>{serviceDetails.service4}</h4>
                     <p className='text-align '>{serviceDetails.description4}</p>
                  </div>
                  <div id='service5' className='col-sm-5 service-container box-shadow-template1 pad border-radius'>
                  <img className='service-img' src={backend+"/"+serviceDetails.img5} alt='services'></img>
                     <h4 className='text-align '>{serviceDetails.service5}</h4>
                     <p className='text-align '>{serviceDetails.description5}</p>
                  </div>
                  <div id='service6' className='col-sm-5 service-container box-shadow-template1 pad border-radius'>
                  <img className='service-img' src={backend+"/"+serviceDetails.img6} alt='services'></img>
                     <h4 className='text-align '>{serviceDetails.service6}</h4>
                     <p className='text-align '>{serviceDetails.description6}</p>
                  </div>
               </div>
               <div className='row margin-bottom'>
                  <h3 className='text-align container-margin'>Gallery Images</h3>
                  <p className='text-align'>Checkout our best in class and amazing products</p>
                  <div className='container mobile-aligned-center'>
                     < div className='row mobile-aligned-center align-center gallery-Image' >
                     <div className='col-sm-10 '>
               
                              <Carousel className='box-shadow border-sm'> 
                              <CarouselItem>
                                 <img src={img1} className='gallery-Image' ></img>
                              </CarouselItem>
                              <CarouselItem>
                                <img src={img2} className='gallery-Image' ></img>
                               </CarouselItem>
                               <CarouselItem>
                                 <img src={img3} className='gallery-Image' ></img>
                              </CarouselItem>
                              <CarouselItem>
                                 <img src={img4} className='gallery-Image'></img>
                              </CarouselItem>
                              <CarouselItem>
                                 <img src={img5} className='gallery-Image'></img>
                              </CarouselItem>
                              <CarouselItem>
                                 <img src={img6} className='gallery-Image' ></img>
                              </CarouselItem>
                              </Carousel>
                              </div>
                     
                  </ div>
               </div></div>

               <div className='row margin-top'>
                  <div className='col align-center'>
                     <form className='userform width-90' onSubmit={handleLeadFormSubmission}>
                        <h3 className='text-align'>Enquire Now</h3>
                        <p className='text-align'>Have a doubt? Enquire now</p>
                        <label className='template_01_Label'>Name</label>
                        <input placeholder='Your Name' name='leadName' onChange={handleLeadDetails} type='text'></input><br></br>
                        <label className='template_01_Label'>Phone No.</label>
                        <input placeholder='Your Phone Number' name='leadPhoneNo' onChange={handleLeadDetails} type='text'></input><br></br>
                        <label className='template_01_Label'>Email Id</label>
                        <input placeholder='Your Email' name='leadEmailId' onChange={handleLeadDetails} type='text'></input><br></br>
                        <button type='submit' className='bookNow margin-bottom'>Submit</button>

                     </form>
                  </div>
               </div>
  
  <div className="container d-flex justify-content-center">
  <div className="row ">
    <div classname="col ">
  
                          <iframe id='gmbframe' className='gmb-block' src={socialMedia.gmb}></iframe>
      
      </div>
    </div></div>

               <div className='row margin-top'>
                  <div className='row'>
                     <div className='col'>
                        <h3 className='text-align '>Timings</h3>
                        <p className='text-align margin-bottom'>We are available at the following Timings</p>
                     </div>
                     <div className='row blue-background timings-box margin-bottom'>
                       
                       <div className='col-sm-2  d-flex justify-content-end'>
                           <img className='icon' src={calendarimage} alt='timings'></img></div>
                           <div className='col-sm-8  d-flex margin10'>
                           <h6 className='day'>Monday: </h6>
                           <p> 6:00 Am to 10:00 Pm</p>
                        </div>
                     </div>
                     <div className='row blue-background timings-box margin-bottom'>
                        <div className='col-sm-2 d-flex justify-content-end'>
                           <img className='icon' src={calendarimage} alt='timings'></img></div>
                           <div className='col-sm-8  d-flex margin10'>
                           <h6 className='day'>Tuesday: </h6>
                           <p> 6:00 Am to 10:00 Pm</p>
                        </div>
                     </div>
                     <div className='row blue-background timings-box margin-bottom'>
                        <div className='col-sm-2 d-flex justify-content-end'>
                        <img className='icon' src={calendarimage} alt='timings'></img></div>
                           <div className='col-sm-8  d-flex margin10'>
                           <h6 className='day'>Wednesday: </h6>
                           <p> 6:00 Am to 10:00 Pm</p>
                        </div>
                     </div>
                     <div className='row blue-background timings-box margin-bottom'>
                        <div className='col-sm-2 d-flex justify-content-end'>
                        <img className='icon' src={calendarimage} alt='timings'></img></div>
                           <div className='col-sm-8 d-flex margin10 '>
                           <h6 className='day'>Thursday: </h6>
                           <p> 6:00 Am to 10:00 Pm</p>
                        </div>
                     </div>
                     <div className='row blue-background timings-box margin-bottom'>
                        <div className='col-sm-2 d-flex justify-content-end'>
                        <img className='icon' src={calendarimage} alt='timings'></img></div>
                           <div className='col-sm-8  d-flex margin10'>
                           <h6 className='day'>Friday: </h6>
                           <p> 6:00 Am to 10:00 Pm</p>
                        </div>
                     </div>
                     <div className='row blue-background timings-box margin-bottom'>
                        <div className='col-sm-2 d-flex justify-content-end '>
                        <img className='icon' src={calendarimage} alt='timings'></img></div>
                           <div className='col-sm-8 d-flex margin10'>
                           <h6 className='day'>Saturday: </h6>
                           <p> 6:00 Am to 10:00 Pm</p>
                        </div>
                     </div>

                    
                  </div>
               </div>

               </div>


               
            </div>

           
         </div>
         </>
        
)
    }
}