import React from "react";
import './homepage.css'
import Footer from "./footer"
import Header  from './header';





export default function Terms(){
    return(
        <div className="main-container">

            <div className="container">
                <div className="row">
                    <div className="col">
                        <Header></Header>
                    </div>
                </div>
            </div>

            <div className="container">
            <div className="row">
                <div className="col">
                    <h1><br></br>Terms & Conditions<br></br><br></br></h1>
                    <p>
                        
                            <b>Last updated on Jul 19 2024</b><br></br>
                            <br></br>
                            For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean EVRIDDHI TECH PRIVATE LIMITED, whose registered/operational office is 107 Amrapali plaza, Amrapali Circle Jaipur Jaipur RAJASTHAN 302021 . "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.                         
                            <br></br><br></br>Use of the Website:<br></br>
                    </p>
                </div>
            </div>
            </div>



          



            <div className="container">
            <div className="row">
                <div className="col">
                <br></br><br></br>
                            <h4>‍1.1 Eligibility:</h4>
                            <p>‍By accessing the Website, you represent that you are at least 18 years old and have the legal capacity to enter into these Terms. If you are accessing the Website on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.</p>
                   
                </div>
            </div>
            </div>



            <div className="container">
            <div className="row">
                <div className="col">
                <br></br><br></br>
                            <h4>1.2 Permitted Use:</h4>
                            <p>You may use the Website for lawful purposes and in accordance with these Terms. You agree not to engage in any activity that interferes with or disrupts the functioning of the Website or infringes upon the rights of others.</p>
                   
                </div>
            </div>
            </div>

            <div className="container">
            <div className="row">
                <div className="col">
                <br></br><br></br>
                            <h4>‍1.3 User Accounts:</h4>
                            <p>‍Certain features of the Service may require you to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate and up-to-date information when creating your account.                            
                            </p>
                </div>
            </div>
            </div>






            
            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>Use of Information:</h4>
                        <br></br><br></br>
                        <h4>‍2.1 Provision of Service:</h4>
                        <p>‍We use the collected information to provide, maintain, and improve the Service, including delivering the functionality and features you request, responding to your inquiries or support requests, and personalizing your user experience.</p>
                    </div>
                </div>
            </div>




            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h5>Your use of the website and/or purchase from us are governed by following Terms and Conditions:</h5>
                        <p>The content of the pages of this website is subject to change without notice.

                            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                            Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.
                            Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
                            All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.
                            Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.
                            From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.
                            You may not create a link to our website from another website or document without EVRIDDHI TECH PRIVATE LIMITED’s prior written consent.
                            Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India .
                            We, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time</p>
                    </div>
                </div>
            </div>







            <div className="container">
                <div className="row">
                    <div className="col">
                        <Footer></Footer>
                    </div>
                </div>
            </div>
        </div>




    );}