import Header from "./Header"
import './css/dashboard.css'
import Sidebar from "./leftSidebar"
import Footer from "./Footer";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './css/App.css';






export default function Appointments(){

    const [businessDetails, setBusinessDetails]= useState('')



    // variables user is the current session user and navigate is the page they need to navigate to

   const backend="https://api.evriddhi.com"
    const [user, setName]= useState('')
    const navigate= useNavigate();
    axios.defaults.withCredentials=true;



    // calling api to display details on dashboard 


    useEffect(()=>{
        axios.get(backend+'/dashboard')
        .then(res=> {
            if(res.data.valid){
              setName(res.data.sessionData)
              console.log(res.data.sessionData)
        }else{
            console.log(res.data)
        }
    })
    .catch(err=> console.log(err));




        //get business description
        axios.get(backend+'/businessDetals').then(res=> {
            if( res.data.businessDetails.businessName== null){

            }else{
             setBusinessDetails(res.data.businessDetails)
            }
         }).catch(err=> {console.log("error")});
  
      
    },[]);





    // jsx code for the display page

   return( 
   <div className="container-fluid ">
    <div className="row bg-gray vh-100">
        <div className="col-sm-2 bg-white box-shadow border-round-lite static-section ">
            <Sidebar/>
        </div>
        <div className="col-sm-10 scrollable-section">
            <div className="content">
            <Header />
            <div className="row center">
           <div className="row center">
              <div className="col-sm-12 width-98 padding-md bg-white  box-shadow border-round-lite">

                <div className="row">
                    <div className="col-sm-6 ">
                      <h1 className="margin-pos-2 ">Appointments</h1>
                    </div>
                    <div className="col-sm-6 align-end">
                        <button className="details-btn btn-submit border-round-lite box-shadow">Download</button>
                    </div>
                </div>
                <div className="row center margin-top-pos-5">
                    <table className="box-shadow">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Concern</th>
                            <th>Phone Number</th>
                            <th>Email</th>
                            <th>Timings</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Image</td>
                                <td>Image</td>
                                <td>Action</td>
                                <td>Action</td>
                                <td>Image</td>
                            </tr>
                            <tr>
                                <td>Image</td>
                                <td>Image</td>
                                <td>Action</td>
                                <td>Action</td>
                                <td>Image</td>
                            </tr>
                            <tr>
                                <td>Image</td>
                                <td>Image</td>
                                <td>Action</td>
                                <td>Action</td>
                                <td>Image</td>
                            </tr>
                            <tr>
                                <td>Image</td>
                                <td>Image</td>
                                <td>Action</td>
                                <td>Action</td>
                                <td>Image</td>
                            </tr>
                        </tbody>

                    </table>
               
               </div>
               <div className="row">
                
               </div>
           </div>
           </div>

           </div>

        <div className="row  margin-pos-2 center ">
            <div className="col-sm-12 width-99-desktop">
            <div className="box-shadow border-round-lite padding-md bg-red">
               <h4>Gateway to your online presence</h4>
               <p>Create an AI-generated website with relevant local keywords and 
                  SEO optimization in 10 minutes. Implement local SEO strategies, 
                  including Google My Business, citations, and content updates, 
                  to rank in the area within 30 days.
               </p>
               <p ><a href="" className="link-underline">Subscribe Now</a> </p>
            </div>
            </div>
        </div>


        <div className="row margin-pos-2 center col-gap-40 ">
            <div className="col-sm-5 bg-white box-shadow border-round-lite  padding-md margin-top-pos-10px" >
            <h3 className="left-align">User Details</h3><br></br>
                      <p><span>Username:</span> {user.name}</p>
                      <p><span>Email:</span> {user.email}</p>
                      <p><span>Phone Number:</span> {user.phoneno}</p>
                      <p><span>Subscription:</span> {user.subscriptionPlan}</p>
                        
            </div>
            <div className="col-sm-5 col-sm-6 bg-white box-shadow border-round-lite margin-top-pos-10px  padding-md">
                <h2>Business Info</h2><br></br>
                 <p><span>Business Name:</span> {businessDetails.businessName}</p>
                 <p><span>Business Contact:</span> {businessDetails.businessContact}</p>
                 <p><span>Business Email:</span> {businessDetails.businessEmail}</p>
                 <p><span>Business Whatsapp:</span>{businessDetails.whatsapp} </p>          
            </div>
        </div>
        <Footer/>
        </div>
        </div>
        </div>
        </div>
    )
}

