import React from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LOGOICON from './images/LOGOICON.png'



function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://evriddhi.com/">
          evriddhi.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }



export default function Footer(){

    // footer component of tha page a static display 

    
    return(
        <>
       <div className='container'>
    <div className='row d-flex'>
        <div className='col footer-Gap margin-top hide-on-phone'>
            <img className="logo-footer" src={LOGOICON}></img>
            <h2>Evriddhi</h2>
            <p className="custom-menu-spacing">Email: support@evriddhi.com</p>
            <p className="custom-menu-spacings">Phone: +91 8209507076</p>
        </div>
        <div className='col margin-top'>
        <h3 className="underline">Explore More</h3>
        <br></br>
            <a href="https://evriddhi.com/#home"><h5 className="custom-menu-spacing">Home</h5></a>
            <a href="https://evriddhi.com/#about"><h5 className="custom-menu-spacing">About Us</h5></a>
            <a href="https://evriddhi.com/#services"><h5 className="custom-menu-spacing">Services</h5></a>
            <a href="https://evriddhi.com/#templates"><h5 className="custom-menu-spacing">Templates</h5></a>
        </div>
        <div className='col  margin-top hide-on-phone'>
        <h3 className="underline">Services</h3>
        <br></br>
            <a href=""><h5 className="custom-menu-spacing">Business Card</h5></a>
            <a href=""><h5 className="custom-menu-spacing">AI Creatives</h5></a>
            <a href=""><h5 className="custom-menu-spacing">Lead Generation</h5></a>
            <a href=""><h5 className="custom-menu-spacing">Appointment Creation</h5></a>
            <a href=""><h5 className="custom-menu-spacing">SEO</h5></a>
        </div>
        <div className='col margin-top'>
        <h3 className="underline">Policies</h3>
        <br></br>
             <a href="https://evriddhi.com/termsandconditions"><h5 className="custom-menu-spacing">Terms & Conditions</h5></a>
            <a href="https://evriddhi.com/privacyPolicy"><h5 className="custom-menu-spacing">Privacy Policy</h5></a>
            <a href="https://evriddhi.com/return"><h5 className="custom-menu-spacing">Cancellation Policy</h5></a>
            <a href="https://evriddhi.com/shippingPolicy"><h5 className="custom-menu-spacing">Shipping Policy</h5></a>
         </div>
    </div>


    

</div>


        <div className='row-fluid'>
        <div className='col mt-3'>
        <hr></hr>
        <Copyright />
        </div>
        <div className='col mt-2 d-flex justify-content-center' >
            <InstagramIcon></InstagramIcon>
            <FacebookIcon></FacebookIcon>
            <YouTubeIcon></YouTubeIcon>
            <LinkedInIcon></LinkedInIcon>
        </div>
    </div>



        </>
       
    )
}