
import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import './App.css'
import './homepage.css'
import Footer from "./footer"
import Header  from './header';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Seo from './images/Seo.png'
import LeadGeneration from './images/LeadGeneration.png'
import dIYWEBSITE from './images/dIYWEBSITE.png'
import diyWebsites from './images/diyWebsites.png'
import CustomAdminPannel from './images/CustomAdminPannel.png'
import seoService from './images/seoService.png'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import leadGenerations from './images/leadGenerations.png'
import CheckIcon from '@mui/icons-material/Check';
import CALL from './images/CALL.png';
import businessCard from './images/businessCard.png'
import SEODashboard from './images/SEODashboard.png'








export default function Homepage() {

  




  const navigate= useNavigate();



  // navigate to login

  const navigateToSignup=()=>{
    navigate('/signup')
  }


  const navigateToLogin=()=>{
    navigate('/login')
  }

 

return(
    <>


    {/* Topbar */}
    <div className='container-fluid bg-red' id='home'>
        <div className='row-fluid'>
           
        <div className="moving-text-container center-x col-sm-12">
            <p className="moving-text no-overflow">Build your DIY website with Evriddhi in 5 minutes! 
            Enhance your search engine rankings with pre-designed content for your online presence. 
            </p> 
            
        </div>

       


        </div>
    </div>


       

<Header></Header>


 

{/*Dashboard Images*/}

<div className='container-fluid hide-on-phone '>
        <div className='row-fluid  border-20'>
           
            <Carousel interval={2500} fade>
            <Carousel.Item>
                  <div className='row-fluid d-flex justify-content-center background2 border-20 padding-sm-home' >
                       
                        <div className='col-sm-5 margin-top margin-neg-right'>
                          <div className='row'>
                          <h2 className='padding-sm text-right text-white'>Build Your Brand With The #1 Creative Marketplace</h2>
                          <h5 className='padding-sm text-right text-white h4-mobile' >A new-age solution that lets your prospects, clients, and business partners contact you instantly.!</h5>
                          <div className='row-fluid d-flex justify-content-end margin-top' >
                          <button className="padding-sm width-200 topbarButton button-on-phone" onClick={navigateToSignup}>Sign up Now</button>
                          </div>
                        
                        </div>
                       
                    </div>
                    </div>
                  </Carousel.Item>




            <Carousel.Item >
                      <div className='row-fluid background1 border-20 padding-sm-home'  >
                      <div className='col-sm-5 margin-left ' >
                          <h1 className='padding-sm-home marign-y-auto'>Digital Business Cards</h1>
                          <Stack spacing={1}>
                            <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                          </Stack>
                          <h5 className='padding-sm-home h4-mobile'>A digital business card is the best & easy solution to share your contact information instantly!</h5>
                          <div className='row-fluid d-flex'>
                          <div className='col'><p className='p-mobile hide-on-phone'><CheckCircleRoundedIcon ></CheckCircleRoundedIcon>Business Card</p></div>
                          </div>
                          <div className='row hide-on-phone'>
                          <div className='col'><p className='p-mobile hide-on-phone'><CheckCircleRoundedIcon ></CheckCircleRoundedIcon>Search Engine Ranking</p></div>
                          </div>
                          <div className='row '>
                          <div className='col'><p className='p-mobile hide-on-phone'><CheckCircleRoundedIcon ></CheckCircleRoundedIcon>Rank Higher</p></div>
                          </div>
                      <div className='row gap-menu paddins-sm-home margin-top'>
                    
                        <button className="submit-button bg-red width-200 button-on-phone" onClick={navigateToSignup}>Create a Card</button>
                         <button className="submit-button bg-red width-200 hide-on-phone" onClick={navigateToLogin}>Choose a Template</button></div>
                        </div>
                        
                        <div className='col-sm-5'>
                        </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
        </div>
    </div>


<div className='container hide-laptop'>
<div className='row'>
               <Carousel>
                  <Carousel.Item >
                     
                       <div className='row'>
                          <div className='col'>
                          <img className='width-100-phone border-round-lite' src={businessCard}></img>
                       
                    </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item >
                        <div className='row'>
                          <div className='col'>
                          <img className='width-100-phone border-round-lite' src={SEODashboard}></img>
                        </div></div>
                  </Carousel.Item>
                  </Carousel>
                  </div>
                  </div>

              
    
{/* Trusted */}


<div className='row  center-x hide-on-phone gap-5 top-z padding-sm-home margin-top-phone'>
    <div className='col-sm-2  width-70-phone padding-sm-home box-shadow border-round-lite'>
      <div className='row '> 
      <div className='col center-x'> 
      <img className='icon-home' src={Seo}></img>
      </div>
      </div>
     
      <h3 className='text-center'><span>SEO</span></h3> 
      <p className='text-center'>optimizing websites to rank higher in search engine results</p>
       
    </div>


    <div className='col-sm-2 width-70-phone padding-sm-home box-shadow border-round-lite'>
    <div className='row '> 
      <div className='col center-x'> 
      <img className='icon-home' src={CustomAdminPannel}></img>
      </div>
      </div>
     
      <h3 className='text-center'><span>Admin Panel</span></h3> 
      <p className='text-center'>Interface for managing settings and content on websites</p>
       
    </div>
    


    <div className='col-sm-2 width-70-phone padding-sm-home  box-shadow border-round-lite'>
    <div className='row '> 
      <div className='col center-x'> 
      <img className='icon-home' src={LeadGeneration}></img>
      </div>
      </div>
     
      <h3 className='text-center'><span>Enquiries</span></h3> 
      <p className='text-center'>Get organic enquiries from your genuine customers.</p>
       
    </div>
    <div className='col-sm-2 width-70-phone padding-sm-home  box-shadow border-round-lite'>
    <div className='row '> 
      <div className='col center-x'> 
      <img className='icon-home' src={dIYWEBSITE}></img>
      </div>
      </div>
     
      <h3 className='text-center'><span>DIY Website</span></h3> 
      <p className='text-center'>Create your own website and get ranked higher on search engines.</p>
      </div>
  </div>





    {/* Mid Section */}

<div className='container '>
  <div className='row-fluid padding-lg'>
    <div className='col'>
      <h1 className='text-center phone-heading-main '>Get Your <span>Ready-Made</span> Online Store in 15 Min !</h1>
      <h5 className='text-center h4-mobile margin-top phone-para'>Get your commerce-ready mini-website in just 15 minutes. Sell online hassle-free — no domain purchase, no store setup, no SEO. And yes, AI crafts your content, saving your time and effort!</h5>
    </div>


    <div className='row-fluid margin-top '>
      <div className='col-sm-12 center-x'>
      <button className='button-on-phone bg-red submit-button padding-mobile box-shadow' onClick={navigateToSignup}>Take My Business Online</button>
      </div>
    </div>
  </div>


</div>






{/* Services */}


<div className='row center hide-on-phone' id='service'>
  <div className='col'>
    <h1 className='text-center phone-heading-main'>Our <span>Services</span></h1>
    <div className='row '>
      <div className='col center'>
      <h5 className='text-center width-80 phone-para'>Unlock your online potential with tailored SEO, marketing strategies, web design, and analytics for sustainable business growth</h5>
      </div>
    </div>
  </div>
</div>


<div className='row-fluid flex-laptop margin-top '>
  <div className='col-sm-6 border-heavy '>
    <div className='row margin-top margin-bottom'>
      <div className='col center-x'>
      <img className='width-500 box-shadow-services-left hide-on-phone' src={seoService}></img>
      </div>
    </div>
  </div>

  <div className='col-sm-6 padding-md bg-purple '>
   <h2 className='margin-top'>Search Engine <span>Optimization</span></h2>
   <Stack spacing={1}>
      <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
    </Stack>
   <h5 className='font-weight-sm margin-top'>Unlock your online potential with tailored SEO, marketing strategies, web design, and analytics for sustainable business growth</h5>
   <h5 className='margin-top'><CheckIcon ></CheckIcon> One Time Payment For a Year</h5>
   <h5 ><CheckIcon ></CheckIcon> No Additional Charges</h5>
   <h5 ><CheckIcon ></CheckIcon> Get Ranked On The Best Hyper Local Keyword </h5>
   <div className='col center-x hide-laptop margin-top'>
      <img className='width-500 box-shadow-services-left ' src={seoService}></img>
      </div>
  </div>
</div>


<div className='row-fluid flex-laptop'>
<div className='col-sm-6 padding-md bg-purple '>
   <h2 className='margin-top'>Diy Website <span>Creation</span></h2>
   <Stack spacing={1}>
      <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
    </Stack>
   <h5 className='margin-top'>DIY website creation: Empower yourself with intuitive tools and templates to build and customize your online presence effortlessly</h5>
   <h5 className='margin-top'><CheckIcon ></CheckIcon>Attractive Templates to Choose From</h5>
   <h5 ><CheckIcon ></CheckIcon>Website Creation in 15 Min</h5>
   <h5 ><CheckIcon ></CheckIcon>Get Enquiries From Website</h5>
   <h5 ><CheckIcon ></CheckIcon>Get Appointments From Website</h5>
   <div className='col-sm-12 center-x hide-laptop margin-top'>
      <img className='width-500 box-shadow-services-left' src={diyWebsites}></img>
      </div>
  </div>
 

  <div className='col-sm-6 border-heavy '>
    <div className='row margin-bottom margin-top '>
      <div className='col-sm-12 center-x '>
      <img className='width-500 box-shadow-services-left hide-on-phone'  src={diyWebsites}></img>
      </div>
    </div>
  </div>
  
</div>






<div className='row-fluid flex-laptop border-heavy border hide-on-phone'>
  <div className='col-sm-6 border-heavy '>
    <div className='row margin-top margin-bottom hide-on-phone'>
      <div className='col center-x'>
      <img className='width-500 box-shadow-services-left ' src={leadGenerations}></img>
      </div>
    </div>
  </div>

  <div className='col-sm-6 padding-md bg-purple'>
   <h2 className='margin-top'>Lead Generation & <span>Enquiries</span></h2>
   <Stack spacing={1}>
      <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
    </Stack>
   <h5 className='font-weight-sm margin-top'>Unlock your online potential with tailored SEO, marketing strategies, web design, and analytics for sustainable business growth</h5>
   <h5 className='margin-top'><CheckIcon ></CheckIcon> One Time Payment For a Year</h5>
   <h5 ><CheckIcon ></CheckIcon> No Additional Charges</h5>
   <h5 ><CheckIcon ></CheckIcon> Get Ranked On Hyper Local Keyword For Your Website</h5>
  </div>
</div>






{/* About Us */}



<div className='row-fluid margin-top flex-laptop' id='about'>
  <div className='col-sm-6 padding-md-home margin-top '>
    <h1 className='phone-heading-main'>Over <span>100+ Businesses</span> Onboarded What are You Waiting for?</h1>
    <h5 className='margin-top phone-para'>At Evriddhi, we specialize in crafting top-tier single-page websites tailored for your business, optimizing them to rank prominently on hyperlocal search results, ensuring maximum visibility and impact.</h5>

    <button className="submit-button margin-top bg-red width-200 button-on-phone" onClick={navigateToSignup}>Signup</button>
    <button className="submit-button bg-red margin-left width-200 hide-on-phone" onClick={navigateToLogin}> Login</button>
                       
  </div>

  <div className='col-sm-6'>
  <img className='width-700 padding-md phone-width' src={CALL}></img>
</div>

</div>

  



 
    
          




{/* Footer */}


<div className='container'>
<Footer></Footer></div>

      

    </>
)

}




