import Header from "./Header"
import Sidebar from "./leftSidebar"
import Footer from "./Footer";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";
import * as ReactDOM from 'react-dom/client';
import callIcon from './images/enquiry.png';
import './css/dashboard.css';
import './css/App.css';










export default function Enquiries(){


     
    // variables user is the current session user and navigate is the page they need to navigate to

    const backend="https://api.evriddhi.com"
    const [user, setName]= useState([{}])
    const [leads, setLeads]=useState({})
    const navigate= useNavigate();
    axios.defaults.withCredentials=true;
    const [businessDetails, setBusinessDetails]= useState({
        businessName:' ',
        businessContact:' ',
        businessEmail: ' ',
        whatsapp:' '
    })


    // calling api to display details on dashboard 


    useEffect(()=>{
        axios.get(backend+'/dashboard')
        .then(res=> {
            if(res.data.valid){
              setName(res.data.sessionData)
           //  console.log(res.data.sessionData)
        }else{
           // console.log(res.data)
        }
    })
    .catch(err=> console.log(err));


   
    
    },[]);




    useEffect(()=>{
        axios.get(backend+'/getLeads').then(res=>{
            leadUpdation(res.data.leadDetails)
        }).catch();

         //get business description
         axios.get(backend+'/businessDetals').then(res=> {
           if( res.data.businessDetails.businessName== null){

           }else{
            setBusinessDetails(res.data.businessDetails)
           }
        }).catch(err=> {console.log("error")});
      
    
    
       
    },[]);





    //Lead Updation

    function leadUpdation(leads){
        //console.log(leads)
        var leadTable;
        var i;
        const block=[];

        for(i=0;i<leads.length;i++){
            const number="tel:+91"+leads[i].leadContact;
            leadTable=(
                
                <div className="row center  ">
                        <div className="col-sm-12  col-gap-20 ">
                        <div className="row padding-sm-x center hide-sidebar padding-sm border-round-lite border-bottom-lite ">
                                <div className="col-sm-2 ">
                                    <h5 className="leadData">{leads[i].leadName}</h5>
                                </div>
                                <div className="col-sm-4 hide-on-phone">
                                    <h5 className="leadData">{leads[i].leadEmail}</h5>
                                </div>
                                <div className="col-sm-3 d-flex">
                                <img className="icon-phone" src={callIcon} alt="call"></img>
                                    <h5 className="leadData">{leads[i].leadContact}</h5>
                                </div>
                                <div className="col-sm-2 hide-on-phone">
                                    <h5 className="leadData">{leads[i].createdOn}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid hide-laptop">
                        <div className="row padding-4  border-round-lite border-bottom-lite  d-flex">
                                <div className="col ">
                                    <h5 className="leadData">{leads[i].leadName}</h5>
                                </div>
                                <div className="col d-flex ">
                                  <img className="phone-call-icon" src={callIcon} alt="call"></img>
                                  <a href={number} ><h5 className="leadData">{leads[i].leadContact}</h5></a>  
                                </div>
                            </div>
                        </div>
                   
                   </div>
            )  
            block.push(leadTable);
        }

        const root= ReactDOM.createRoot(document.getElementById("leadData"));
        root.render(block)

        

       
       
    }
    

   


    //Download csv files

    function downloadEnquiry(){
        axios.get(backend+"/downloadCSV")
        .then(res=>{
            if(res.data.valid== true){
            alert("Download Successful")}
            else{
                alert("Unable to Download")
            }
        })
        .catch(err=>{
            alert("Unable to Download")
        })
    }



    


    

    // jsx code for the display page

   return( 
   <div className="container-fluid ">
    <div className="row bg-gray vh-100">
        <div className="col-sm-2 bg-white box-shadow border-round-lite static-section mobile-aligned-center">
            <Sidebar/>
        </div>
        <div className="col-sm-10 scrollable-section mobile-aligned-center">
            <div className="content">
            <Header />
            <div className="row  center mobile-aligned-center margin-pos-3">
             <div className="row width-98 center">
              <div className="col  padding-md bg-white  box-shadow border-round-lite">

                <div className="row">
                    <div className="col-sm-6 padding-phone text-center-phone">
                      <h1 className="margin-pos-2 ">Enquiries & Leads</h1>
                    </div>
                    <div className="col-sm-6 align-end">
                        <button className="details-btn btn-submit border-round-lite box-shadow hide-on-phone" onClick={downloadEnquiry}>Download</button>
                    </div>
                </div>
                <div className="row  margin-top-pos-5 ">
                    <div className="col-sm-12 box-shadow   col-gap-20 border-round-lite">
                        <div className="row padding-sm-x center padding-sm bg-red border-round-lite ">
                            
                            <div className="col-sm-2  hide-on-phone">
                                <h5 className="table-h5">Name</h5>
                            </div>
                            <div className="col-sm-4 hide-on-phone">
                                <h5 className="table-h5">Email Id</h5>
                            </div>
                            <div className="col-sm-3  hide-on-phone">
                                <h5 className="table-h5">Contact Details</h5>
                            </div>
                            <div className="col-sm-2  hide-on-phone">
                                <h5 className="table-h5">Created On</h5>
                            </div>
                            <div className="hide-laptop container-fluid">
                            <div className="row  d-flex ">
                            <div className="col ">
                                <h5 className="table-h5 padding-x-phone">Name</h5>
                            </div>
                            <div className="col">
                                <h5 className="table-h5 padding-x-phone">Contact Details</h5>
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className="row" id="">
                            <div  id="leadData"></div>
                        </div>
                        
               </div>
               </div>
               <div className="row d-flex justify-content-center ">
                            <div className="col d-flex justify-content-center ">
                            <button className="details-btn btn-submit border-round-lite box-shadow hide-laptop" onClick={downloadEnquiry}>Download</button>
                            </div>
            </div>
           </div>
           
           </div>
           </div>
        <div className="row  margin-pos-2 center ">
            <div className="col-sm-12 width-99-desktop">
            <div className="box-shadow border-round-lite padding-md bg-red">
               <h4>Gateway to your online presence</h4>
               <p>Create an AI-generated website with relevant local keywords and 
                  SEO optimization in 10 minutes. Implement local SEO strategies, 
                  including Google My Business, citations, and content updates, 
                  to rank in the area within 30 days.
               </p>
               <p ><a href="" className="link-underline">Subscribe Now</a> </p>
            </div>
            </div>
        </div>


        <div className="row margin-pos-2 center col-gap-40 ">
            <div className="col-sm-5 bg-white box-shadow border-round-lite  padding-md margin-top-pos-10px" >
            <h3 className="left-align">User Details</h3><br></br>
                      <p><span>Username:</span> {user.name}</p>
                      <p><span>Email:</span> {user.email}</p>
                      <p><span>Phone Number:</span> {user.phoneno}</p>
                      <p><span>Subscription:</span> {user.subscriptionPlan}</p>
                        
            </div>
            <div className="col-sm-5 col-sm-6 bg-white box-shadow border-round-lite margin-top-pos-10px  padding-md">
                <h2>Business Info</h2><br></br>
                 <p><span>Business Name:</span> {businessDetails.businessName}</p>
                 <p><span>Business Contact:</span> {businessDetails.businessContact}</p>
                 <p><span>Business Email:</span> {businessDetails.businessEmail}</p>
                 <p><span>Business Whatsapp:</span>{businessDetails.whatsapp} </p>          
            </div>
        </div>
        <Footer/>
        </div>
        </div>
        </div>
        </div>
    )

}

