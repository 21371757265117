import './App.css'
import './login.css'
import  logoCropped from './images/logoCropped.png'
import React, {useState, useEffect, setState, Component} from 'react'
import axios from 'axios';
import * as ReactDOM from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import google from './images/google.png'
import identity from './images/identity.png'
import mini from './images/mini.png'
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.css';





export default function Login() {


  const [open, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };






  const backend="https://api.evriddhi.com"
    const [values, setValues]= useState({
        email :'',
        password: ''
    })

    const [forgotPasswordEmail, setForgotPasswordEmail]=useState({
      otpValidationMail:''
    });





    // Carousel section

    const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
    
    
    const [errors, setErrors]=useState({})
    const navigate= useNavigate();
    const handleSubmit=(event)=>{
        event.preventDefault();
        axios.post(backend+'/login', values).then(res=> {
        if(res.data.valid){
          navigate('/admin/Dashboard');
        }else{
          notValidCredentials()
         }
      })
        .catch(err=> notValidCredentials());
            function notValidCredentials(){
            const invalid_credentials=(<Alert  severity="error">
            Oops! Incorrect Password
          </Alert>)
            const container = document.getElementById('data');
            const root = ReactDOM.createRoot(container);
            root.render(invalid_credentials)
            }
    }

    
    axios.defaults.withCredentials=true;
    useEffect(()=>{
      
      axios.get(backend+'/dashboard')
      .then(res=> {
          if(res.data.valid){
            navigate('/admin/Dashboard/')
      }else{
          navigate('/login');
      }
  })
  .catch(err=> console.log(err));
  },[]);


    const handleInput=(event)=>{
        setValues(prev=> ({...prev,[event.target.name]:[event.target.value]
        }))
    }



    // Hande forgot password


    const handleForgotPassword=(event)=>{
       event.preventDefault();
             setOpen(true);
    }
    

    const forgotPassword=()=>{
      //console.log(forgotPasswordEmail.otpValidationMail + "trying submit")
      if(forgotPasswordEmail== null){
        console.log("Not calling backend")
      }else{
        console.log("calling backend")
        axios.post(backend+'/forgotPassword', forgotPasswordEmail)
        .then(res=>{

           if(res.data.user == "Not_found"){
            const invalid_credentials=(<Alert  severity="success">
            Unable To Send Mail! Please enter Valid Email
          </Alert>)
            const container = document.getElementById('otpText');
            const root = ReactDOM.createRoot(container);
            root.render(invalid_credentials)
           }else if(res.data.valid== true){
            const invalid_credentials=(<Alert  severity="success">
            Password Updated successfully. Kindly Check Your Mail.
          </Alert>)
            const container = document.getElementById('otpText');
            const root = ReactDOM.createRoot(container);
            root.render(invalid_credentials)
           }
        })
        .catch()
      }
    }

    const handleForgotPasswordEmail=(event)=>{
      setForgotPasswordEmail(prev=> ({...prev,[event.target.name]:[event.target.value]}))
     // console.log(forgotPasswordEmail.email)
    }




    


    


  return (

   

     <div className='container-fluid   bg-purple  main-container' id='sign-in-button'>
      
      <React.Fragment>
      <Dialog
       className='hidden-box'
        open={open}
        onClose={handleClose}
       

      >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enteryour registered email
          </DialogContentText>
          <TextField
            onChange={handleForgotPasswordEmail}
            autoFocus
            required
            margin="dense"
            id="name"
            name="otpValidationMail"
            label="Email"
            type="otpValidationMail"
            fullWidth
            variant="standard"
          />
        <div id="otpText"></div>
        </DialogContent>
        <DialogActions>
          <button className='submit-button' onClick={handleClose}>Cancel</button>
          <button className='submit-button' type="submit" onClick={forgotPassword}>Submit</button>
        </DialogActions>
      </Dialog>
    </React.Fragment>



      <div className='row  round-border padding-sm-4'>
        <div className='col-sm-6 padding-4  bg-white  box-shadow round-border'>
           <div className='row center-all'>
            <div className='col-sm-6 d-flex justify-content-center'>
           <a href='https://evriddhi.com/'><img className='margin-left-lg-phone logophone' src={logoCropped} alt='vriddhi'></img></a>
            </div></div>

      <div className='container-fluid'>
      <div className='row '>
        <div className='col-sm-12'>
        <div id="data" className='empty-alert col-sm-12'></div> 
        <div className='col-sm-12'><br></br>
         <TextField  autoFocus required color='warning'  id="outlined-basic" className='login-input width-75-phone-login width-vw-70-login' label="Email-Id" variant="outlined" onChange={handleInput} name='email' type="email" /><br></br>
         <br></br><br></br>
         <TextField autoFocus required color='warning'  id="outlined-basic" className='login-input width-75-phone-login width-vw-70-login' label="Password" variant="outlined" onChange={handleInput} name='password'  type="password" />

     <button className=' forgot-password' onClick={handleForgotPassword}><span>Forgot Password</span></button>
     <br></br><br></br>  

     
      <h6 className='login-page'>New Here? Please <a href='https://evriddhi.com/signup'><span>Signup</span></a> to continue </h6>
      <button type='login-submit ' className='submit-button padding-sm-4' onClick={handleSubmit}>Login</button>
    </div>
    </div>
    </div>
    </div>
    </div>


    <div className='col center-all '>
   <Carousel fade className='margin-left '>
      <Carousel.Item>
      <img className='image-login margin-left-lg' src={mini}>
          </img>  
      </Carousel.Item>
      <Carousel.Item>
      <img className='image-login margin-left-lg' src={identity}>
          </img>  
      </Carousel.Item>
      <Carousel.Item>
        <img className='image-login margin-left' src={google}>
          </img>  
      </Carousel.Item>
    </Carousel>
      
    </div>
      </div>
  </div>
  

  );

 
}

