import React from "react";
import "./css/dashboard.css"
import 'bootstrap/dist/css/bootstrap.css';
import './css/App.css';


export default function Footer(){

    // footer component of tha page a static display 

    
    return(
        <>
        <div className="row d-flex bg-red margin-top-pos-10px">
            <h5 className="margin-top-pos-10px text-center">Powered By @evriddhi : Your one step solution to online growth.</h5>
        </div>
        </>
       
    )
}
