import React from "react";
import { useNavigate } from 'react-router-dom';
import logoCropped from './images/logoCropped.png'
import './homepage.css'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css';






export default function Header(){

    const navigate= useNavigate();



    // navigate to login
  
    const navigateToLogin=()=>{
      navigate('/login')
    }
   
    return(
        <>
        <div className="container">
        
            <div className="row  padding-sm">
                <div className="col-sm-3 center-y">
                    <img className="logo-icon-home" src={logoCropped}></img>
                </div>
               
                <div className="col-sm-9 d-flex gap-menu end-x center-y" >

                   <a href="https://evriddhi.com/#home"><h5 className="hide-on-phone">Home</h5></a>
                   <a href="https://evriddhi.com/#about"><h5 className="hide-on-phone">About</h5></a>
                   <a href="https://evriddhi.com/#service"><h5 className="hide-on-phone">Services</h5></a>
                  <a> <h5 className="hide-on-phone">Blogs</h5></a>
                  <div className="row d-flex gap-10 hide-on-phone">
                    <div className="col">
                    <button className="submit-button bg-red hide-on-phone width-100 padding-y padding-md" onClick={navigateToLogin}>Login</button></div></div>
                   
                </div>
            </div>
        </div>
      
        </>
    )

}


