import Header from "./Header"
import './css/form.css'
import Sidebar from "./leftSidebar"
import Footer from "./Footer";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import * as ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './css/dashboard.css';
import './css/App.css';
import Alert from '@mui/material/Alert';

import TextField from '@mui/material/TextField';





export default function Settings(){

   

   const inputRef= useRef(null);
   const backend= "https://api.evriddhi.com"
   const [userData,setUserData]= useState('')
   const [settingData, handleSettingData]=useState({
      fullName:'',
      phoneNoChange:'',
      gstnUpdate:'',
      password:''
   })


   // Saving changes to backend

   const handleSubmit=()=>{
      axios.post(backend+"/updateSettings", settingData)
      .then(res=>{
         const invalid_credentials=(<Alert  severity="success">
         Your Detals Have Been Saved successfully!!
       </Alert>)
         const container = document.getElementById('updatedSettings');
         const root = ReactDOM.createRoot(container);
         root.render(invalid_credentials)
      })
      .catch(err=> {
         const invalid_credentials=(<p><span>Your details have not been saved!!</span></p>)
         const container = document.getElementById('updatedSettings');
         const root = ReactDOM.createRoot(container);
         root.render(invalid_credentials)
      
      })
   }


   // Handling User Input
   const handleInput=(event)=>{
      handleSettingData(prev=> ({...prev,[event.target.name]:[event.target.value]}))
   }


   // 
   var admin='';

   useEffect(()=>{

      axios.get(backend+'/dashboard')
      .then(res=>{
         setUserData(res.data.sessionData)
       //  console.log(res.data.sessionData)
         if(res.data.sessionData.emailStatus== null){
            const invalid_credentials=(<p className=''><span>Unverified email- </span>Please verify your email</p>)
            const container = document.getElementById('verified-email');
            const root = ReactDOM.createRoot(container);
            root.render(invalid_credentials)
           
         }else{
            const invalid_credentials=(<p className=''><span className="green-span">Valid Email Id </span></p>)
            const container = document.getElementById('verified-email');
            const root = ReactDOM.createRoot(container);
            root.render(invalid_credentials)
         }


         if(res.data.sessionData.phoneNoStatus== null){
            const invalid_credentials=(<p className=''><span>Unverified Phone- </span>Verify your Phone Number</p>)
            const container = document.getElementById('verified-Contact');
            const root = ReactDOM.createRoot(container);
            root.render(invalid_credentials)
           
         }else{
            const invalid_credentials=(<p className=''><span className="green-span">Valid Phone Number </span></p>)
            const container = document.getElementById('verified-Contact');
            const root = ReactDOM.createRoot(container);
            root.render(invalid_credentials)
         }


         if(res.data.sessionData.adminImage== null){
            admin= backend+'/changeCover.jpg'
           // console.log(admin)
            const invalid_credentials=(<>
            <img className=" settings-logo box-shadow " src={admin} onClick={handleButtonClick} alt="image icon"></img>
            <input type="file" onChange={handleGalleryUpload} ref={inputRef} style={{display:"none"}}></input>
           
            </>)
            const container = document.getElementById('imageContainer');
            const root = ReactDOM.createRoot(container);
            root.render(invalid_credentials)
           
            
         }else{
            admin= backend+'/'+res.data.sessionData.adminImage
            const invalid_credentials=(<>
            <img className=" settings-logo box-shadow " src={admin} onClick={handleButtonClick} alt="image icon"></img>
            <input type="file" onChange={handleGalleryUpload} ref={inputRef} style={{display:"none"}}></input>
           </>
            )
            const container = document.getElementById('imageContainer');
            const root = ReactDOM.createRoot(container);
            root.render(invalid_credentials)
         }
      })
      .catch()

   },[]);





   // Get Handle admin Image Update

   const handleGalleryUpload=(event)=>{
      const galleryImage= new FormData();
      galleryImage.append('image', event.target.files[0]);
      axios.post(backend+"/updateAdminImage", galleryImage)
      .then(res=>{
          console.log("called")
      })
      .catch()
  }


  // Handle image upload button

function handleButtonClick(){
   inputRef.current.click();
}






   



   return( <div className="container-fluid ">
   <div className="row bg-gray vh-100">
       <div className="col-sm-2 bg-white box-shadow border-round-lite static-section ">
           <Sidebar/>
       </div>
       <div className="col-sm-10 scrollable-section mobile-aligned-center">
           <div className="content ">
           <Header />
           <div className="row center padding-y mobile-aligned-center">
           <div className="row ">
            <div className="col-sm-12 width-98 padding-md bg-white  box-shadow border-round-lite">

                  <div className="row padding-y-10">
                  <div className="col-sm-4">
                     <h5>Profile Picture: <span className="grey-text small-font">(250x250)</span></h5></div>
                     <div className="col-sm-7" id="imageContainer">
                     </div>
                  </div><br></br>

                  <div className="row padding-y-10">
                  <div className="col-sm-4">
                     <h5>Full Name<span className="red-text">*</span></h5></div>
                     <div className="col-sm-7">
                        <TextField  autoFocus required color='warning'  id="fullName-change" className='login-input' label="Full Name" variant="outlined" onChange={handleInput} name='fullName' type="fullName" /><br></br>
                     </div>
                  </div><br></br>

                  <div className="row padding-y-10">
                  <div className="col-sm-4">
                     <h5>Email Id<span className="red-text">*</span></h5></div>
                     <div className="col-sm-7">
                        <TextField  autoFocus required color='warning'  id="emailChange" className='login-input' label={userData.email} variant="outlined" disabled onChange={handleInput} name='emailChange' type="emailChange" /><br></br>
                        <div id="verified-email"></div>
                     </div>
                  </div>

                  <div className="row padding-y-10">
                  <div className="col-sm-4">
                     <h5>Contact Details<span className="red-text">*</span></h5></div>
                     <div className="col-sm-7">
                     <TextField  autoFocus required color='warning'  id="phoneNoChange" label="Phone Number" className='login-input' variant="outlined"  onChange={handleInput} name='phoneNoChange' type="phoneNoChange" /><br></br>
                     <div id="verified-Contact"></div>
                     </div>
                  </div>

                  <div className="row padding-y-10">
                  <div className="col-sm-4">
                     <h5>GSTN Number</h5></div>
                     <div className="col-sm-7">
                        <TextField  autoFocus required color='warning' inputProps={{ maxLength: 20 }}  id="gstn" label="GSTN" className='login-input' variant="outlined"  onChange={handleInput} name='gstnUpdate' type="gstnUpdate" /><br></br>
                     </div>
                  </div><br></br>

                  <div className="row padding-y-10">
                  <div className="col-sm-4">
                     <h5>Password</h5></div>
                     <div className="col-sm-7">
                     <TextField  autoFocus required color='warning'  id="password" label="Password" className='login-input' variant="outlined"  onChange={handleInput} name='password' type="password" /><br></br>
                     </div>
                  </div>
               <div className="row padding-y">
                  <div className="col-sm-12 d-flex justify-content-start">
               <button className="submit-button " type="submit" onClick={handleSubmit}>Update Settings</button>
               </div>
               </div>
               <div id="updatedSettings"></div>

               </div>
               </div>
               </div>
             
               
       <div className="row  margin-pos-2 center ">
           <div className="col-sm-12 width-99-desktop">
           <div className="box-shadow border-round-lite padding-md bg-red">
              <h4>Gateway to your online presence</h4>
              <p>Create an AI-generated website with relevant local keywords and 
                 SEO optimization in 10 minutes. Implement local SEO strategies, 
                 including Google My Business, citations, and content updates, 
                 to rank in the area within 30 days.
              </p>
              <p ><a href="" className="link-underline">Subscribe Now</a> </p>
           </div>
           </div>
       </div>


      
       <Footer/>
       </div>
       </div>
       </div>
       </div>
    )
}