
import profile_Icon from "./images/profile_Icon.png"
import './css/dashboard.css'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";
import LOGOICON from './images/LOGOICON.png';
import 'bootstrap/dist/css/bootstrap.css';
import './css/App.css';








export default function Header(){


    
    // user details for the sesion is gathered 

    const [user, setName]= useState('')
    const navigate= useNavigate();
    axios.defaults.withCredentials=true;
    const backend= "https://api.evriddhi.com"



    // getting user details for the session 


    useEffect(()=>{
        axios.get(backend+'/dashboard')
        .then(res=> {
            if(res.data.valid){
              setName(res.data.sessionData)
              console.log(res.data.sessionData)
        }else{
            console.log(res.data)
        }
    }).catch(err=> console.log(err));
    },[]);
    
    




    // calling api to handle logout using async await function
    

    const logout=async ()=>{
        const response = await axios(backend+"/logout", { method: "POST"});
        navigate('/login');
    }







    // create site status

        const createSite= document.getElementById('createSite')
        const visitSite= document.getElementById('visitSite')

        if(user.siteLink!= null){
            visitSite.style.display="block";
            createSite.style.display="none";
        }

            const openInNewTab = (url) => {
            window.open(url, "_blank", "noreferrer");
            }
        


        const handleCreateSiteButton=()=>{
            axios.post(backend+'/createSite')
            .then(res=>{
                if(res.data.valid){
                    alert("Site created successfully!")
                }else{
                    alert("Error Creating The Site, Please complete your business information first")
                }
            })
            .catch(console.log("Error Creating The Site, Please complete your information first"));
           // alert("Site Created Successfully, Visit Your Site at my.evriddhi.com/user.busnessName")
        }
 


    // JSX code for header of the dashboard page

    return(
       
        <div className="container-fluid  box-shadow border-round-lite ">
            
            
            <div className="row bg-white border-round-lite hide-header">
                <div className="col-sm-2 center">
                    <div className="row" id="createSite">
                        <button className=" visitSite-button" onClick={handleCreateSiteButton}>Create Site &gt;&gt;</button>
                    </div>
                    <div className="row" id="visitSite">
                        <button className="  visitSite-button"   onClick={()=> openInNewTab("/"+user.siteLink)}>Visit Site  &gt;&gt;</button>
                    </div>
                
                   
                </div>
               
                <div className="hide-header col-sm-10 d-flex justify-content-end align-items-center margin-sm-x padding-xs-y">
                <img src={LOGOICON} alt="image" className="header-logout cover-image-sidebar box-shadow border-round"></img>
                <button onClick={logout} className="btn-simple">Logout</button>
            </div>

        </div>
        </div>
    );
}