import React from "react";
import './homepage.css'
import Footer from "./footer"
import Header  from './header';





export default function Shipping(){
    return(
        <div className="main-container">

            <div className="container">
                <div className="row">
                    <div className="col">
                        <Header></Header>
                    </div>
                </div>
            </div>

            <div className="container">
            <div className="row">
                <div className="col">
                    <h1><br></br>Shipping and Delivery<br></br><br></br></h1>
                    <p>
                        
                            <b>Effective Date: 13th July 2024</b><br></br>
                            <br></br>
                            Welcome to the website of Evriddhi Tech Private Limited ("Evriddhi," "we," "us," or "our").This Shipping & Delivery Policy ("Policy") outlines the terms and conditions governing the shipping and delivery of products and materials related to our SaaS-based services (collectively, the "Service").
                            <br></br><br></br>Shipping Methods and Timelines:<br></br>
                    </p>
                </div>
            </div>
            </div>



            <div className="container">
            <div className="row">
                <div className="col">
                <br></br><br></br>
                            <h4>‍1.1 Delivery Method:</h4>
                            <p>For the Service we provide, there is no physical shipping involved. The Service is delivered electronically through our web-based platform accessible via our website.</p>
                   
                </div>
            </div>
            </div>






            
            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>Order Processing:</h4>
                        <br></br><br></br>
                        <h4>‍2.1 Service Activation:</h4>
                        <p>‍Upon successful purchase or subscription to our Service, you will receive an email confirmation containing the necessary information to activate your account and access the Service. Please ensure that the provided email address is accurate and accessible.</p>
                    </div>
                </div>
            </div>


            <div className="container">
            <div className="row">
                <div className="col">
                <br></br><br></br>
                            <h4>‍2.2 Service Availability:</h4>
                            <p>Once your account is activated, you will have immediate access to the Service, subject to any specified setup or onboarding processes mentioned during the subscription or purchase.</p>
                </div>
            </div>
            </div>








            <div className="container">
                <div className="row">
                    <div className="col">
                        <Footer></Footer>
                    </div>
                </div>
            </div>
        </div>




    );}