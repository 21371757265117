import React from "react";
import './homepage.css'
import Footer from "./footer"
import Header  from './header';





export default function PrivacyPolicy(){
    return(
        <div className="main-container">
            <div className="row">
                <Header></Header>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1><br></br>Privacy Policy</h1>
                        <br></br>
                    </div>
                    </div>
            </div>


            <div className="container">
            <div className="row">
                <div className="col">
                    <p>
                        
                            <b>Effective Date: 17th April 2023</b><br></br>
                            <br></br>
                            This Privacy Policy describes how Evriddhi Tech Private Limited ("Evriddhi," "we," "us," or "our") collects, uses, and discloses personal information when you use our SaaS-based product and related services (collectively, the "Service") available on our website at https://evriddhi.com (the "Website").
                            <br></br><br></br>Information We Collect:<br></br>
                    </p>
                </div>
            </div>
            </div>



            <div className="container">
            <div className="row">
                <div className="col">
                <br></br><br></br>
                            <h4>‍1.1 Personal Information:</h4>
                            <p>We may collect personal information that you voluntarily provide to us when you use our Service or interact with the Website, such as your name, email address, contact information, billing details, and any other information you choose to provide.</p>
                   
                </div>
            </div>
            </div>



            <div className="container">
            <div className="row">
                <div className="col">
                <br></br><br></br>
                            <h4>‍1.2 Usage Data:</h4>
                            <p>‍We may collect non-personal information about your interaction with the Service and Website, including your IP address, browser type, operating system, referring URLs, and other technical information. We may use cookies and similar tracking technologies to collect this data.</p>
                   
                </div>
            </div>
            </div>



            
            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>Use of Information:</h4>
                        <br></br><br></br>
                        <h4>‍2.1 Provision of Service:</h4>
                        <p>‍We use the collected information to provide, maintain, and improve the Service, including delivering the functionality and features you request, responding to your inquiries or support requests, and personalizing your user experience.</p>
                    </div>
                </div>
            </div>



            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>‍2.2 Communication:</h4>
                        <p>‍We may use your personal information to communicate with you, provide updates, newsletters, and marketing materials related to our Service, and respond to your comments or questions.</p>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>‍2.3 Billing and Payment:</h4>
                        <p>‍If you subscribe to a paid version of our Service, we may collect and process your billing information to process payments and fulfil orders.</p>
                    </div>
                </div>
            </div>



            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>2.4 Aggregated Data:</h4>
                        <p>‍We may aggregate and anonymize the collected data to generate statistical and analytical insights to improve our Service, develop new features, and for other business purposes.</p>

                        <h4><br></br>Data Sharing and Disclosure<br></br></h4>
                    </div>
                </div>
            </div>



            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>‍3.1 Third-Party Service Providers:</h4>
                        <p>‍We may engage trusted third-party service providers to perform certain business-related functions on our behalf. These providers may have access to personal information only as necessary to perform their functions and are obligated to protect and secure that information.</p>
                     </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>3.2 Legal Requirements:</h4>
                        <p>‍We may disclose personal information if required to do so by law or in response to valid legal requests from government authorities, including to meet national security or law enforcement requirements.</p>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col"><br></br><br></br>
                        <h4>3.3 Business Transfers:</h4>
                        <p>‍In the event of a merger, acquisition, or sale of all or a portion of our assets, personal information may be transferred or disclosed as part of the transaction. We will notify you via email or a prominent notice on our Website of any change in ownership or use of your personal information.</p>
                    </div>
                </div>
            </div>








            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Footer></Footer>
                    </div>
                </div>
            </div>




        </div>

    );
}