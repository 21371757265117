import React, {useState} from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as ReactDOM from 'react-dom';
import {red } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';










function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://evriddhi.com/">
        evriddhi.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
    palette: {
        primary: {
          main: "rgb(179, 21, 21)",
        },
        secondary: {
          main: '#ff8a80',
        },
      },
});




// Main Function

export default function SignIn() {


    const today = new Date();
    const formattedDate = today.toLocaleDateString();
    const backend= "https://api.evriddhi.com"
    const [open, setOpen] = React.useState(false);
    const [openReferal, setOpenReferal] = React.useState(false);

  
  
    const handleClose = () => {
      setOpen(false);
      setOpenReferal(false);
    };
  

    var userOtp='';
    var referal='';


    const [values, setValues]=useState({
        username:'',
        password: '',
        email:'',
        phoneno:'',
        Date: today.getDate()+'-'+today.getMonth()+'-'+today.getFullYear()+'/'+today.getHours()+':'+today.getMinutes(),
    })
    const [errors, setErrors]=useState({})
    const navigate= useNavigate();
 

    function handleSubmit(event){
             event.preventDefault();
             setOpen(true);
              console.log(values)
           // console.log("backend called?"+ values)
            axios.post(backend+'/signup', values).then(res=>{
             if(!res.data.valid){
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              User Present! please try logging in or reset the password.
            </Alert>
             }else{

             }
            }).catch(err=> {
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
               Error Creating an account.
              </Alert>
            });
            
    }

    const handleInput=(event)=>{
        setValues(prev=> ({...prev,[event.target.name]:[event.target.value]
        }))
    }


    
    
    
     // Handle referal code

    const handleReferalCode= (e)=>{
      referal= e.target.value
    }
    
    const enterReferal=(e)=>{
      setOpenReferal(true);
    }

    const referalCode=(event)=>{
      axios.post(backend+'/referal', referal).
      then(res=>{
        if(res.data.isTrue){

        }else{
          
        }
      }).catch(err=>{

      })
    }

    
    
    
    
    


// Handle user OTP
    
 const handleUserOtp=(event)=>{
   userOtp= event.target.value
 }


 const onOtpSubmit=()=>{
     const data= {
      otp: userOtp,
      email: values.email
     }
     axios.post(backend+'/checkOtp', data)
     .then(res=>{
      if(res.data.valid){
        navigate('/login')
      }else{
        invalidOtp()
      }
      
     })
     .catch(err=> console.log(err))
     function invalidOtp(){
      const invalidOtp=(<Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
      Error Creating an account.
     </Alert>)
      const container = document.getElementById('otpError');
      const root = ReactDOM.createRoot(container);
      root.render(invalidOtp)
    }
 }

  return (
    <div className='d-flex center justify-content-center bg-grey '>
       <div className='row margin-top-neg-40' >
       <div className='col bg-white box-shadow padding-2 bg-white border-round-lite vh100-mobile' >

    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
             onChange={handleInput}
              margin="normal"
              required
              fullWidth
              name="username"
              label="Username"
              type="username"
              id="username"
              autoComplete="current-password"
            />
            <TextField
            onChange={handleInput}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              onChange={handleInput}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

           

           <TextField
             onChange={handleInput}
              margin="normal"
              required
              fullWidth
              name="phoneno"
              label="Phone Number"
              type="phoneno"
              id="phoneno"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Signup
            </Button>

            <Grid container>
              <Grid item>
                <Link href="/login" variant="body5" color="text.secondary">
                  {"Have an account? Login"}
                </Link>
                <p onClick={enterReferal} class="coupon"><a href="#">Have a Code?</a></p>

              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>



    <React.Fragment>
      <Dialog
       className='hidden-box'
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle>Signup</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Enter The OTP Received on The Registered Email.
          </DialogContentText>
          <TextField
            onChange={handleUserOtp}
            autoFocus
            required
            margin="dense"
            id="name"
            name="otp"
            label="OTP"
            type="otp"
            fullWidth
            variant="standard"
          />
        <div id="otpError"></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={onOtpSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    




      <React.Fragment>
      <Dialog
       className='hidden-box'
        open={openReferal}
        onClose={handleClose}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle>Referal Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Enter The Referal Code Here.
          </DialogContentText>
          <TextField
            onChange={handleReferalCode}
            autoFocus
            required
            margin="dense"
            id="name"
            name="referal"
            label="Referal Code"
            type="Referal"
            fullWidth
            variant="standard"
          />
        <div id="referal"></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={referalCode}>Submit</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>




    </div></div></div>
  );


  
}